import formatCurrency from "../../common/formatCurrency";
import { createColumn } from "../../common/createColumn";
import { shortenID } from "../../common/shortenID";

export const quotationListColumns = [
  createColumn("id", "QUOTE NO", 120, {
    format: shortenID,
    color: "primary",
    bold: true,
  }),
  createColumn("createdOn", "DATE", 100),
  createColumn("prescriptionID", "PRESC. NO", 120, {
    format: shortenID,
    color: "primary",
    bold: true,
  }),
  createColumn("noToQuote", "PRESC ITEMS", 80),
  createColumn("noQuoted", "QUOTED", 80),
  createColumn("total", "TOTAL (KSH)", 80, {
    align: "right",
    format: (value: number) => formatCurrency(value),
    bold: true,
  }),
  createColumn("status", "STATUS", 150, { align: "center" }),
  createColumn("action", "ACTION", 120, { align: "center" }),
];
