import { createTheme, Theme } from "@material-ui/core/styles";

const theme: Theme = createTheme({
  shape: {
    borderRadius: 8,
  },
  palette: {
    secondary: {
      main: "#ef404c",
    },
    action: {
      hover: "rgb(224 243 255 / 80%)",
      selected: "rgb(224 243 255 / 100%)",
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontWeightMedium: 600,
    fontSize: 12,
    body1: {
      fontSize: "0.78rem",
    },
    button: {
      fontWeight: 400,
      textTransform: "none",
    },
  },
});

export default theme;
