import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Link } from "react-router-dom";
import { Card, CardContent } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import CardHeader from "@material-ui/core/CardHeader";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import routes from "../../constants/routes";
import { deleteUser, getAllUsers, makeUserAdmin } from "./../../actions/users/index";
import { connect } from "react-redux";
import { isRoleAdmin, isRoleAgentAdmin, Role } from "./../../types/enums/Role";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  EmptySpace: {
    marginBottom: theme.spacing(1),
  },
  table: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "table",
    },
  },
  responsiveUserList: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      borderTop: "1px solid gray",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userListRow: {
    borderBottom: "1px solid gray",
    padding: "10px 0",
  },
  boxSpace: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  createUserButton: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "1rem",
    },
  }
}));

const UserList = ({
  users,
  getAllUsers,
  deleteUser,
  makeUserAdmin,
  userLoading,
  currentUser,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currUserRole, setCurrUserRole] = useState("");

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (users) setState(users.data);
  }, [users]);

  useEffect(() => {
    setLoading(userLoading);
  }, [userLoading]);

  useEffect(() => {
    if (currentUser) setCurrUserRole(currentUser.data.user.role);
  }, [currentUser]);

  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure you want to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteUser(id);
            setState((prev) => prev.filter((user) => user.id !== id));
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleUpdate = (id) => (e) => {
    e.preventDefault();
    history.push(routes.user.update(id));
  };

  const handleCreateUser = () => {
    history.push(routes.user.create);
  };

  const handleToggleAdmin = (userID) => () => {
    const user = state.find((user) => user.id === userID);

    const isAdmin = isRoleAgentAdmin(user.role);

    makeUserAdmin(user.fullName, userID, !isAdmin);

    setState((prev) => {
      user.role = isAdmin ? Role.PHARMACY_AGENT : Role.PHARMACY_AGENT_ADMIN;
      prev[prev.indexOf(user)] = user;
      return prev;
    });
  };

  return (
    <>
      <Breadcrumbs className={classes.EmptySpace} aria-label="breadcrumb">
        <Link color="inherit" href="/">
          Manage Users
        </Link>
        <Typography color="textPrimary">Users List</Typography>
      </Breadcrumbs>
      <div className={classes.root}>
        <Card>
          <CardHeader title="All Users" subheader="All User Details" />
          <CardContent>
            <Box display="flex">
              <Box flexGrow={1} className={classes.boxSpace}>
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  gutterBottom
                ></Typography>
              </Box>
              <Box className={classes.createUserButton}>
                <Button variant="contained" color="primary" onClick={handleCreateUser}>
                  Create New User
                </Button>
              </Box>
            </Box>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">#</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Phone</TableCell>
                  {currUserRole === "PharmacyAdmin" && (
                    <TableCell align="left">Admin</TableCell>
                  )}
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell align="right">{index + 1}</TableCell>
                    <TableCell align="left">{user.fullName}</TableCell>
                    <TableCell align="left">{user.email}</TableCell>
                    <TableCell align="left">{user.phoneNumber}</TableCell>

                    {currUserRole === "PharmacyAdmin" && (
                      <TableCell align="left">
                        <Checkbox
                          checked={isRoleAgentAdmin(user.role) || isRoleAdmin(user.role)}
                          onChange={handleToggleAdmin(user.id)}
                          disabled={isRoleAdmin(user.role) || loading}
                          size="small"
                          name="rememberMe"
                        />
                      </TableCell>
                    )}
                    <TableCell align="left">
                      {user.role !== "PharmacyAdmin" && (
                        <ButtonGroup color="primary" aria-label="Action buttons">
                          <Button onClick={handleUpdate(user.id)}>Edit</Button>
                          <Button onClick={() => handleDelete(user.id)} color="secondary">
                            Del
                          </Button>
                        </ButtonGroup>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {/* Responsive user list */}
            <div className={classes.responsiveUserList}>
              {state.map((user, index) => {
                return (
                  <div key={index} className={classes.userListRow}>
                    <div style={{padding: "0.5rem 0"}}>
                      <b>Name: </b>
                      <span>{user.fullName}</span>
                    </div>
                    <div style={{padding: "0.5rem 0"}}>
                      <b>Email: </b>
                      <span>{user.email}</span>
                    </div>
                    <div style={{padding: "0.5rem 0"}}>
                      <b>Phone: </b>
                      <span>{user.phoneNumber}</span>
                    </div>
                    {currUserRole === "PharmacyAdmin" && (
                      <div>
                        <b>Admin: </b>
                        <Checkbox
                          checked={isRoleAgentAdmin(user.role) || isRoleAdmin(user.role)}
                          onChange={handleToggleAdmin(user.id)}
                          disabled={isRoleAdmin(user.role) || loading}
                          size="small"
                          name="rememberMe"
                        />
                      </div>
                    )}
                    {user.role !== "PharmacyAdmin" && (
                      <div>
                        <b>Action: </b>
                        <ButtonGroup color="primary" aria-label="Action buttons">
                          <Button onClick={handleUpdate(user.id)}>Edit</Button>
                          <Button onClick={() => handleDelete(user.id)} color="secondary">
                            Del
                          </Button>
                        </ButtonGroup>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  users: state.users.results,
  userLoading: state.user.loading,
  currentUser: state.account.results,
});

export default connect(mapStateToProps, { getAllUsers, deleteUser, makeUserAdmin })(UserList);
