export enum QuotationStatus {
  PENDING = "pending",
  WON = "won",
  LOST = "lost",
  CANCELLED = "cancelled",
}

export const isStatusPending = (status: QuotationStatus) => status === QuotationStatus.PENDING;

export const isStatusWon = (status: QuotationStatus) => status === QuotationStatus.WON;

export const isStatusLost = (status: QuotationStatus) => status === QuotationStatus.LOST;

export const isStatusCancelled = (status: QuotationStatus) =>
  status === QuotationStatus.CANCELLED;
