import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  IconButton,
  Dialog,
  Divider,
} from "@material-ui/core";
import { loadPrescriptions } from "../../actions/prescriptions";
// import { SearchOutlined, FilterList } from "@material-ui/icons";
// import SortIcon from "@mui/icons-material/Sort";
import CloseIcon from "@mui/icons-material/Close";

import CreateQuote from "./CreateQuote";
import { RootState } from "../../reducers/index.types";
import { Prescription } from "../../models/prescription/Prescription";
import { PaginatedServiceResult } from "../../models/common/ServiceResult/PaginatedServiceResult";
import { PharmaceuticalItem } from "../../models/prescription/PharmaceuticalItem";
import { prescriptionRowColumns as columns } from "./index.columns";
import PrescriptionRow from "./PrescriptionRow/index";
import { containerHeight } from "./../../components/Layout/MainLayout/index.constants";
import Spinner from "./../../components/common/Spinner";
import { NonPharmaceuticalItem } from "./../../models/prescription/NonPharmaceuticalItem";
import useInterval from "./../../hooks/useInterval";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableHead: {
    "& th:first-child": {
      borderTopLeftRadius: theme.shape.borderRadius,
    },
    "& th:last-child": {
      borderTopRightRadius: theme.shape.borderRadius,
    },
  },
  container: {
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      height: containerHeight,
    },
  },
  selectedStyles: {
    "& > *": {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.primary.main,
    },
  },
  hide: {
    display: "none",
  },
  responsivePrescriptionRows: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      borderBottom: "1px solid gray",
      padding: "10px 0",
      cursor: "pointer",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  responsivePrescriptionList: {
    [theme.breakpoints.down("md")]: {
      display: "block",
      backgroundColor: "white",
      padding: "10px",
      borderRadius: "10px",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  selectedRowStyles: {
    backgroundColor: "rgba(239, 64, 76, 0.08)",
  },
  filterSection: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "center",
    ".MuiButton-outlined": {
      padding: "10px 30px",
    },
  },
  prescriptionListSection: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  prescriptionListRows: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "table-row",
    },
  },
  paginationRow: {
    "& .MuiToolbar-gutters": {
      padding: "0",
    },
  },
  heightAdjustment: {
    [theme.breakpoints.down("md")]: {
      marginTop: "20vh",
      display: "flex",
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "calc(100vh - 250px)",
    },
  },
}));

type Props = {
  prescriptionsToQuote: PaginatedServiceResult<Prescription> | null;
  loadPrescriptions: () => void;
  loading: boolean;
};

const PrescriptionList: React.FC<Props> = ({
  prescriptionsToQuote,
  loadPrescriptions,
  loading,
}) => {
  const classes = useStyles();
  const [prescriptions, setPrescriptions] = useState(new Array<Prescription>());
  const [pharmaceuticalItems, setPharmaceuticalItems] = useState(
    new Array<PharmaceuticalItem>(),
  );
  const [nonPharmaceuticalItems, setNonPharmaceuticalItems] = useState(
    new Array<NonPharmaceuticalItem>(),
  );
  const [selectedPresc, setSelectedPresc] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openItemsModal, setOpenItemsModal] = useState(false);

  const handleItemsModalClose = () => {
    setOpenItemsModal(false);
  };

  const openModal = () => {
    setOpenItemsModal(true);
  };

  const removePrescription = (quotationID: string) => {
    setPharmaceuticalItems(new Array<PharmaceuticalItem>());
    setNonPharmaceuticalItems(new Array<NonPharmaceuticalItem>());
    setPrescriptions((prev) => prev.filter((presc) => presc.id != quotationID));
    setSelectedPresc(null);
  };

  useInterval(loadPrescriptions);

  // Check if selected prescription is expired and remove any expired
  useEffect(() => {
    const prescription = prescriptions.find((presc) => presc.id === selectedPresc);

    if (prescription && new Date(prescription.expiry) < new Date()) {
      if (selectedPresc) removePrescription(selectedPresc);
      // set prescription items with prescriptions that have not expired
      setPrescriptions((prev) => prev.filter((presc) => new Date(presc.expiry) < new Date()));
    }
  });

  useEffect(() => {
    if (prescriptionsToQuote) {
      setPrescriptions(prescriptionsToQuote.data);
      setPage(prescriptionsToQuote.pageNumber);
      setRowsPerPage(prescriptionsToQuote.pageSize);
      setCount(prescriptionsToQuote.totalRecords);

      if (!selectedPresc && prescriptionsToQuote.totalRecords > 0) {
        setSelectedPresc(prescriptionsToQuote.data[0].id);
        setPharmaceuticalItems(prescriptionsToQuote.data[0].pharmaceuticalItems);
        setNonPharmaceuticalItems(prescriptionsToQuote.data[0].nonPharmaceuticalItems);
      }
    }
  }, [prescriptionsToQuote]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  // Handle selecting a prescription to quote
  const handleQuote = (toQuote: Prescription) => {
    setSelectedPresc(toQuote.id);
    setPharmaceuticalItems(toQuote.pharmaceuticalItems);
    setNonPharmaceuticalItems(toQuote.nonPharmaceuticalItems);
  };
  return (
    <>
      <Grid
        container
        className={clsx({
          [classes.hide]: !prescriptions.length,
          [classes.prescriptionListSection]: prescriptions.length,
        })}
      >
        {/* Prescription List */}
        <Grid container item direction="column" xs={6} component={Box} pr="1rem">
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="prescription list table">
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prescriptions
                    .filter((presc) => new Date(presc.expiry) > new Date())
                    .map((prescription) => (
                      <PrescriptionRow
                        key={prescription.id}
                        prescription={prescription}
                        selected={selectedPresc === prescription.id}
                        classes={classes}
                        handleQuote={handleQuote}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              component="div"
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {loading && <LinearProgress />}
          </Paper>
        </Grid>
        <Grid
          item
          container
          direction="column"
          xs={6}
          component={Paper}
          justifyContent="space-between"
        >
          {/* QuotePrescription side panel  */}
          <CreateQuote
            pharmaceuticalItems={pharmaceuticalItems}
            nonPharmaceuticalItems={nonPharmaceuticalItems}
            prescriptionID={selectedPresc || ""}
            removePrescription={removePrescription}
            deliveryLocation={
              prescriptions.find((presc) => presc.id === selectedPresc)?.deliveryLocation ||
              "N/A"
            }
          />
        </Grid>
      </Grid>

      {/* Responsive prescription list */}
      <div>
        <div
          className={clsx({
            [classes.hide]: !prescriptions.length,
            [classes.responsivePrescriptionList]: prescriptions.length,
          })}
        >
          <div style={{ borderBottom: "1px solid gray" }}>
            {loading && <LinearProgress />}
            <Typography variant="h5" style={{ padding: "1rem 0", fontWeight: "bold" }}>
              Prescriptions
            </Typography>

            {/* <div style={{ marginTop: "1rem" }}>
              <TextField
                fullWidth
                id="standard-bare"
                variant="outlined"
                placeholder="Search"
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchOutlined />
                    </IconButton>
                  ),
                }}
              />
            </div>
            <div className={classes.filterSection}>
              <Button
                variant="outlined"
                startIcon={<FilterList />}
                style={{ marginRight: "1rem" }}
              >
                Filter
              </Button>
              <Button variant="outlined" startIcon={<SortIcon />}>
                Sort
              </Button>
            </div> */}
          </div>
          {prescriptions
            ?.filter((presc) => new Date(presc.expiry) > new Date())
            .map((prescription) => {
              return (
                <PrescriptionRow
                  key={prescription.id}
                  prescription={prescription}
                  selected={selectedPresc === prescription.id}
                  classes={classes}
                  handleQuote={handleQuote}
                  openModal={openModal}
                />
              );
            })}
          <div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              className={classes.paginationRow}
            />
          </div>
        </div>
        <Dialog
          style={{ width: "100vw" }}
          open={openItemsModal}
          onClose={handleItemsModalClose}
        >
          <div style={{ marginLeft: "auto" }}>
            <IconButton
              color="inherit"
              aria-label="close modal"
              edge="start"
              onClick={handleItemsModalClose}
            >
              <CloseIcon />
            </IconButton>
          </div>

          <Divider />

          <CreateQuote
            pharmaceuticalItems={pharmaceuticalItems}
            nonPharmaceuticalItems={nonPharmaceuticalItems}
            prescriptionID={selectedPresc || ""}
            removePrescription={removePrescription}
            deliveryLocation={
              prescriptions.find((presc) => presc.id === selectedPresc)?.deliveryLocation ||
              "N/A"
            }
            handleItemsModalClose={handleItemsModalClose}
          />
        </Dialog>
      </div>

      {/* To show when the prescriptions are not there */}
      {prescriptions?.length > 0 ? null : (
        <Grid
          container
          className={clsx({
            [classes.heightAdjustment]: true,
          })}
        >
          {loading ? (
            <Spinner />
          ) : (
            <Typography variant="h4" align="center" component={Box} width={0.75}>
              You currently have no prescriptions to quote on
            </Typography>
          )}
        </Grid>
      )}
    </>
  );
};

PrescriptionList.propTypes = {
  prescriptionsToQuote: PropTypes.any.isRequired,
  loadPrescriptions: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state: RootState) => ({
  prescriptionsToQuote: state.prescriptions.results,
  loading: state.prescriptions.loading,
});

export default connect(mapStateToProps, { loadPrescriptions })(PrescriptionList);
