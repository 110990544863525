import {
  PRESCRIPTIONS_LOADING,
  PRESCRIPTIONS_RECEIVED,
} from "../../actions/prescriptions/index.constants";
import { PrescriptionActions } from "../../actions/prescriptions/index.types";
import { PrescriptionState } from "./index.types";
import { PRESCRIPTIONS_ERROR } from "./../../actions/prescriptions/index.constants";

export default function (state = new PrescriptionState(), action: PrescriptionActions) {
  switch (action.type) {
    case PRESCRIPTIONS_LOADING:
      return PrescriptionState.setLoading();
    case PRESCRIPTIONS_RECEIVED:
      return PrescriptionState.setReceived(action.payload);
    case PRESCRIPTIONS_ERROR:
    default:
      return state;
  }
}
