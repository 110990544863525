import React from "react";
import { Toaster as ReactHotToaster } from "react-hot-toast";
import { useTheme } from "@material-ui/core";

const Toaster: React.FC = () => {
  const theme = useTheme();
  return (
    <>
      <ReactHotToaster
        toastOptions={{
          style: {
            userSelect: "none",
            backgroundColor: theme.palette.grey[100],
            border: theme.palette.warning.light,
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
          },
        }}
      />
    </>
  );
};

export default Toaster;
