import { PurchaseOrder } from "./../../models/purchaseOrder/PurchaseOrder";

const createData = (
  id: string,
  date: string,
  prescNo: string,
  quoteNo: string,
  patient: string,
  delivery: string,
  amount: number,
): PurchaseOrder => {
  return { id, date, prescNo, quoteNo, patient, delivery, amount };
};

export const purchaseOrderRows = [
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
  createData("007", "14/04/2021", "2845", "234", "Dennis Henry", "home delivery", 1200),
];
