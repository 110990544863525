import { ServiceResult } from "../../models/common/ServiceResult/ServiceResult";
import { LoginResponse } from "../../models/loginResponse/LoginResponse";
import { Role } from "../../types/enums/Role";
import { BaseState } from "../index.types";
// Get Account State
export class AccountState extends BaseState<LoginResponse> {
  resetPassword: boolean = false;
  authenticated: boolean = false;
  rememberMe: boolean = false;

  constructor() {
    super();
    const results = localStorage.getItem("user");
    if (results) {
      if (this.results && !this.results.data) localStorage.clear();
      this.results = JSON.parse(results);
      this.authenticated = true;
      this.rememberMe = true;
    }

    this.checkResetPassword();
  }

  unAuthorize() {
    localStorage.clear();
    this.authenticated = false;
  }

  static getInitialState(): AccountState {
    return new AccountState();
  }

  static getEmptyState(): AccountState {
    const state = new AccountState();
    state.unAuthorize();
    return state;
  }

  static setUserAndToken(
    user: ServiceResult<LoginResponse>,
    forgetPassword: boolean,
  ): AccountState {
    const state = new AccountState();
    state.results = user;
    state.authenticated = true;
    if (!forgetPassword) {
      setUserToLocalStorage(user);
      state.rememberMe = true;
    }
    state.checkResetPassword();
    return state;
  }

  setRole(roles: Role[]) {
    if (this.results) {
      const role: Role = roles.includes(Role.PHARMACY_ADMIN)
        ? Role.PHARMACY_ADMIN
        : roles.includes(Role.PHARMACY_AGENT_ADMIN)
        ? Role.PHARMACY_AGENT_ADMIN
        : Role.PHARMACY_AGENT;

      this.results.data.user.role = role;
      if (this.rememberMe) setUserToLocalStorage(this.results);
      console.log(role);
    }
  }

  checkResetPassword() {
    const password = localStorage.getItem("password");
    this.resetPassword = password === "WelcomeToPonea123!";
  }

  static setConfirmEmail(): AccountState {
    const state = new AccountState();
    state.resetPassword = false;
    return state;
  }

  static setChangePassword(): AccountState {
    const state = new AccountState();
    state.unAuthorize();
    return state;
  }

  setLoading(): AccountState {
    this.loading = true;
    return this;
  }

  static setLogout(): AccountState {
    const state = new AccountState();
    state.unAuthorize();
    return state;
  }
}

export const setToLocalStorage = (params: { key: string; value: string }[]) => {
  params.forEach(({ key, value }) => {
    localStorage.setItem(key, value);
  });
};
function setUserToLocalStorage(user: ServiceResult<LoginResponse>) {
  setToLocalStorage([{ key: "user", value: JSON.stringify(user) }]);
}
