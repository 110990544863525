import uris from "../../constants/uris";
import { handleError } from "../notification/index.types";
import { dashboardValuesLoading, dashboardValuesReceived, dashboardError } from "./index.types";
import { httpClient } from "./../index";


export const getDashboardValues = () => async (dispatch: Function) => {
	try {
      await dispatch(dashboardValuesLoading());
      const data = await httpClient.get(uris.dashboard.getValues);

      await dispatch(dashboardValuesReceived(data));

	} catch (error) {
      await dispatch(handleError(error, dispatch, dashboardError));
	}
  };