import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Link } from "react-router-dom";
import { Card, CardContent } from "@material-ui/core";
import { Dialog, DialogTitle, DialogContent,DialogActions } from "@material-ui/core";

import CardHeader from "@material-ui/core/CardHeader";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { connect } from "react-redux";
import { loadDispatchedWorkOrders, dispatchWorkOrder } from "./../../actions/workOrders/index";
import useInterval from "./../../hooks/useInterval";
import { shortenID } from "./../../common/shortenID";


const useStyles = makeStyles((theme) => ({
  workOrderList: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
      flexGrow: 1,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  EmptySpace: {
    marginBottom: theme.spacing(1),
  },
  responsiveWorkOrderList: {
    [theme.breakpoints.down("md")]: {
      display: "block",
      backgroundColor: "white",
      padding: "10px",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  responsiveWorkOrderRows: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid gray",
    padding: "10px 0",
  },
  tableHead: {
    color: 'red'
  }
}));


const DispatchedWorkOrderList = ({ workOrders, loadDispatchedWorkOrders, dispatchWorkOrder }) => {
  const classes = useStyles();
  const [state, setState] = useState([]);
  const [openView, setOpenView] = useState(false)
  const [viewWorkOrder, setViewWorkOrder] = useState({})

  useInterval(loadDispatchedWorkOrders)

  const handleView = (work_order) => {
	setOpenView(true)
	setViewWorkOrder(work_order)
  }

  const handleCloseView = () => {
      setOpenView(false)
      setViewWorkOrder({})
  }

  const handleDispatch = async (id) => {
	const order = state.find((s) => s.id === id);
	if (order) {
		const done = await dispatchWorkOrder(id)
		if (done) {
			setState((prevState) =>
				prevState.map((workOrders) => {
				if (workOrders.id === id) {
					workOrders.dispatched = true;
				}
				return workOrders;
        }),
      );

		}
	}
}

  useEffect(() => {
    if (workOrders) setState(workOrders.data);
  }, [workOrders]);


  const WorkOrderDialog = (open, work_order) => {
	const pharms = work_order?.pharmaceuticalQuotationItems === undefined ? [] : work_order.pharmaceuticalQuotationItems
	const npharms = work_order?.nonPharmaceuticalQuotationItems === undefined ? [] : work_order.nonPharmaceuticalQuotationItems
	const items2  = pharms.concat(npharms)
	return (
		<Dialog
			open={open}
			style={{ width: "100vw" }}
			onClose={handleCloseView}
		>
			<DialogTitle style={{color: 'red'}}>
				Work Order Details #{work_order.work_order_id}
			</DialogTitle>
			<DialogContent>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell align='left'>Product</TableCell>
							<TableCell align='right'>Quantity</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{items2.map((item, index)=>{
							return (
								<TableRow key={index}>
									<TableCell align='left'>{item.name}</TableCell>
									<TableCell align='right'>{item.quantity}</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseView} color='warning'>Close</Button>
			</DialogActions>

		</Dialog>
	)
}

  return (
    <>
      <Breadcrumbs className={classes.EmptySpace} aria-label="breadcrumb">
        <Link color="inherit" href="/">
          Work Orders
        </Link>
        <Typography color="textPrimary">WorkOrders List</Typography>
      </Breadcrumbs>
      <div className={classes.workOrderList}>
        <Card>
          <CardHeader title="All Work Orders" subheader="Work Orders" />
          <CardContent>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  gutterBottom
                ></Typography>
              </Box>
              <Box></Box>
            </Box>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{color:"red"}}>
                <TableRow>
				  <TableCell align="left">ID</TableCell>
                  {/* <TableCell align="left">Prescription Ref</TableCell> */}
                  <TableCell align="left">Patient Name</TableCell>
                  <TableCell align="left">Delivery Location</TableCell>
                  <TableCell align="left">Delivery Date</TableCell>
                  <TableCell align="left">Delivery Time</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.map((workOrder, index) => (
                  <TableRow key={index}>
					<TableCell align="left">{workOrder.work_order_id}</TableCell>
                    {/* <TableCell align="left">{shortenID(workOrder.prescriptionID)}</TableCell> */}
                    <TableCell align="left">{workOrder.patient_name}</TableCell>
                    <TableCell align="left">{workOrder.delivery_location}</TableCell>
                    <TableCell align="left">{workOrder.delivery_date}</TableCell>
                    <TableCell align="left">{workOrder.delivery_time}</TableCell>
                    <TableCell align="center">
					    <Button
							variant='contained'
							size='small'
							color='primary'
							style={{ marginTop: '10px', whiteSpace: 'nowrap', marginRight:'10px' }}
							onMouseUp={()=>{handleView(workOrder)}}
						>
							View
						</Button>
                        <Button 
							variant='contained'
							size='small'
							color='primary'
							disabled={workOrder.dispatched}
							onMouseUp={()=>{handleDispatch(workOrder.id)}}
							style={{ marginTop: '10px', whiteSpace: 'nowrap' }}
						>
							{workOrder.dispatched ? 'Disptached!' : 'Dispatch'}
						</Button>
                      {/* </ButtonGroup> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>

      {/* Responsive work order list */}
      <div className={classes.responsiveWorkOrderList}>
        {state.map((workOrder, index) => {
          return (
            <div key={index} className={classes.responsiveWorkOrderRows}>
              <div style={{ marginBottom: "10px" }}>
                <b style={{ fontSize: "1rem" }}>Prescription Ref: </b>
                <span style={{ color: "#3f51b5", fontSize: "1rem" }}>
                  {shortenID(workOrder.prescriptionID)}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "50%", fontSize: "0.85rem" }}>
                  <div>
                    <b>Patient Name: </b>
                    <span>{workOrder.patientName}</span>
                  </div>
                  <div>
                    <b>No of Items: </b>
                    <span>{workOrder.noOfDeliveryItems}</span>
                  </div>
                  <div>
                    <b>Delivery Fee: </b>
                    <span>{workOrder.deliveryFee}</span>
                  </div>
                  <div>
                    <b>Amount: </b>
                    <span>{workOrder.total}</span>
                  </div>
                </div>
                <div style={{ width: "50%", fontSize: "0.85rem" }}>
                  <div>
                    <b>Date: </b>
                    <span>{workOrder.deliveryDate}</span>
                  </div>
                  <div>
                    <b>Status: </b>
                    <span>{workOrder.status}</span>
                  </div>
                  <div>
                    <span>
                      <ButtonGroup color="primary" aria-label="outlined primary button group">
						<Button>View</Button>
                        <Button>Disptach</Button>
                      </ButtonGroup>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
	  {WorkOrderDialog(openView, viewWorkOrder)}
    </>
  );
};

const mapStateToProps = (state) => ({
  workOrders: state.workOrders.results,
});

export default connect(mapStateToProps, { loadDispatchedWorkOrders, dispatchWorkOrder })(DispatchedWorkOrderList);
