import {
  CLEAR_MESSAGES,
  ERROR_MESSAGE,
  HANDLE_ERROR,
  SUCCESS_MESSAGE,
} from "../../actions/notification/index.constants";
import { NotificationActions } from "../../actions/notification/index.types";
import { NotificationState } from "./index.types";

export default function (state = new NotificationState(), action: NotificationActions) {
  switch (action.type) {
    case ERROR_MESSAGE:
      return NotificationState.setErrorMessage(action.payload);
    case SUCCESS_MESSAGE:
      return NotificationState.setSuccessMessage(action.payload);
    case CLEAR_MESSAGES:
      return NotificationState.clearMessage();
    case HANDLE_ERROR:
      return NotificationState.handleError(action.payload);
    default:
      return state;
  }
}
