import { createColumn } from "../../../../common/createColumn";

export const confirmPharmaQuoteColumns = [
  createColumn("name", "DRUG NAME", 170),
  createColumn("dosage", "DOSAGE"),
  createColumn("duration", "DURATION"),
  createColumn("stocked", "STOCKED", 80, { align: "center" }),
  createColumn("genericDrug", "GENERIC", 170, { align: "center" }),
  createColumn("amount", "AMOUNT", 100, { align: "center" }),
  createColumn("quantity", "QUANTITY", 100, { align: "center" }),
  createColumn("total", "TOTAL", 100, { align: "right" }),
];

export const confirmNonPharmaQuoteColumns = [
  createColumn("name", "ITEM NAME", 170),
  createColumn("amount", "AMOUNT", 100, { align: "center" }),
  createColumn("quantity", "QUANTITY", 100, { align: "center" }),
  createColumn("total", "TOTAL", 100, { align: "right" }),
];
