export const LIST_USER_LOADING = "LIST_USER_LOADING";

export const SINGLE_USER_LOADING = "SINGLE_USER_LOADING";

export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";

export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";

export const MAKE_USER_ADMIN_SUCCESS = "MAKE_USER_ADMIN_SUCCESS";

export const USERS_ERROR = "USERS_ERROR";

export const USER_ERROR = "USER_ERROR";
