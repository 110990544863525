export enum Role {
  PHARMACY_ADMIN = "PharmacyAdmin",
  PHARMACY_AGENT = "PharmacyAgent",
  PHARMACY_AGENT_ADMIN = "PharmacyAgentAdmin",
}

export const isRoleAgent = (role: Role) => role === Role.PHARMACY_AGENT;

export const isRoleAdmin = (role: Role) => role === Role.PHARMACY_ADMIN;

export const isRoleAgentAdmin = (role: Role) => role === Role.PHARMACY_AGENT_ADMIN;
