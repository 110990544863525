import { PaginatedServiceResult } from "../../models/common/ServiceResult/PaginatedServiceResult";
import { GetQuotation } from "../../models/getQuotation/GetQuotation";
import { BasePaginatedState } from "../index.types";

export class DashboardState extends BasePaginatedState<GetQuotation> {
  static setLoading(): DashboardState {
    const state = new DashboardState();
    state.loading = true;
    return state;
  }

  static setReceived(dashboard_values: PaginatedServiceResult<GetQuotation>): DashboardState {
    const state = new DashboardState();
    state.results = dashboard_values;
    state.loading = false;
    return state;
  }

  static setEmptyState(): DashboardState {
    return new DashboardState();
  }
}
