import { BaseState } from "../index.types";
import { BasePaginatedState } from "./../index.types";
import { User } from "./../../models/user/User";
import { PaginatedServiceResult } from "./../../models/common/ServiceResult/PaginatedServiceResult";
import { ServiceResult } from "./../../models/common/ServiceResult/ServiceResult";

export class SingleUserState extends BaseState<User> {
  static setEmptyState(): SingleUserState {
    const state = new SingleUserState();
    return state;
  }

  static setLoading(): SingleUserState {
    const state = new SingleUserState();
    state.loading = true;
    return state;
  }

  static setReceived(payload: ServiceResult<User>): SingleUserState {
    const state = new SingleUserState();
    state.results = payload;
    state.loading = false;
    return state;
  }
}

export class ListUserState extends BasePaginatedState<User> {
  static setLoading(): ListUserState {
    const state = new ListUserState();
    state.loading = true;
    return state;
  }

  static setReceived(data: PaginatedServiceResult<User>): ListUserState {
    const state = new ListUserState();
    state.results = data;
    state.loading = false;
    return state;
  }
}
