import { PaginatedServiceResult } from "../../models/common/ServiceResult/PaginatedServiceResult";
import { CreateQuotation } from "../../models/createQuotation/CreateQuotation";
import { Prescription } from "../../models/prescription/Prescription";
import uris from "../../constants/uris";
import { createQuoteError, quotationCreated, quotationSending } from "./index.types";
import { handleError } from "../notification/index.types";
import { sendSuccessMessage } from "./../notification/index.types";
import { notificationMessages } from "./../../constants/notifications";
import { httpClient } from "./../index";

export const createQuotation = (quotation: CreateQuotation) => async (dispatch: Function) => {
  type DataType = PaginatedServiceResult<Prescription>;
  try {
    dispatch(quotationSending());

    const data: DataType = await httpClient.post(uris.quotation.create, quotation);

    dispatch(quotationCreated(data));
    dispatch(sendSuccessMessage(notificationMessages.createQuotation.success));
  } catch (error) {
    dispatch(handleError(error, dispatch, createQuoteError));
  }
};
