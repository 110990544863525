import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Button,
  Dialog,
  TableContainer,
  Typography,
  Divider,
  Paper,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { RootState } from "../../../reducers/index.types";
import CloseIcon from "@mui/icons-material/Close";

import { createQuotation } from "../../../actions/createQuote";

import ConfirmQuote from "./ConfirmQuote";
import { PharmaceuticalItem } from "../../../models/prescription/PharmaceuticalItem";
import { CreateQuotation } from "../../../models/createQuotation/CreateQuotation";
import {
  CreateNonPharmaQuoteItemStateModel,
  CreatePharmaQuoteItemStateModel,
} from "./index.types";
import formatCurrency from "../../../common/formatCurrency";
import CreatePharmQuoteItem from "./CreatePharmQuoteItem";
import { shortenID } from "../../../common/shortenID";
import { NonPharmaceuticalItem } from "./../../../models/prescription/NonPharmaceuticalItem";
import CreateNonPharmQuoteItem from "./CreateNonPharmQuoteItem";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
      maxHeight: "640px",
    },
  },
  toolbar: {
    borderBottom: "1px solid rgb(224, 224, 224)",
    backgroundColor: "rgb(250 250 250)",
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  container: {
    [theme.breakpoints.down("md")]: {
      height: "100vh",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 226.6px)",
      overflowX: "auto",
    },
  },
  responsivePrescItemList: {
    [theme.breakpoints.down("md")]: {
      display: "block",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  viewImageBtn: {
    "&:hover": {
      color: "blue",
      cursor: "pointer",
    },
    color: "black",
  },
  prescriptionImage: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "50vh",
    },
    [theme.breakpoints.up("md")]: {
      width: "50vw",
      height: "60vh",
    },
  },
  downloadImageBtn: {
    marginLeft: "auto",
    padding: "20px 15px",
    borderRadius: "10px",
    fontSize: "medium"
  }
}));

type Props = {
  pharmaceuticalItems: PharmaceuticalItem[];
  nonPharmaceuticalItems: NonPharmaceuticalItem[];
  prescriptionID: string;
  createQuotation: Function;
  quotationSending: boolean;
  removePrescription: Function;
  deliveryLocation: string;
  handleItemsModalClose?: () => void | undefined;
};

const CreateQuote: React.FC<Props> = ({
  pharmaceuticalItems,
  nonPharmaceuticalItems,
  prescriptionID,
  createQuotation,
  quotationSending,
  removePrescription,
  deliveryLocation,
  handleItemsModalClose,
}) => {
  const classes = useStyles();

  const [pharmItemState, setPharmItemState] = useState<PharmaceuticalItem[]>([]);
  const [nonPharmItemState, setNonPharmItemState] = useState<NonPharmaceuticalItem[]>([]);
  const [pharmaQuotes, setPharmaQuotes] = useState<CreatePharmaQuoteItemStateModel[]>([]);
  const [nonPharmaQuotes, setNonPharmaQuotes] = useState<CreateNonPharmaQuoteItemStateModel[]>(
    [],
  );
  const [total, setTotal] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);

  const handlePharmaChange = (newQuoteState: CreatePharmaQuoteItemStateModel) => {
    // Getting the state from the children component
    const currState = pharmaQuotes.filter(
      (quote) => quote.pharmaceuticalItemID !== newQuoteState.pharmaceuticalItemID,
    );
    setPharmaQuotes([...currState, newQuoteState]);
  };

  const handleNonPharmaChange = (newQuoteState: CreateNonPharmaQuoteItemStateModel) => {
    // Getting the state from the children component
    const currState = nonPharmaQuotes.filter(
      (quote) => quote.nonPharmaceuticalItemID !== newQuoteState.nonPharmaceuticalItemID,
    );
    setNonPharmaQuotes([...currState, newQuoteState]);
  };

  const handleGenerate = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    if (handleItemsModalClose) {
      handleItemsModalClose();
    }
  };

  const handleImageModalOpen = () => {
    setOpenImageModal(true);
  };

  const handleImageModalClose = () => {
    setOpenImageModal(false);
  };

  const calculateTotal = () => {
    let currTotal = 0;
    pharmaQuotes.map((quote) => (currTotal += +quote.amount * +quote.quantity));
    nonPharmaQuotes.map((quote) => (currTotal += +quote.amount * +quote.quantity));
    setTotal(currTotal + +deliveryFee);
  };

  useEffect(() => {
    setSubmitting(quotationSending);
  }, [quotationSending]);

  // Getting the total when the quotations change
  useEffect(() => {
    calculateTotal();
  }, [pharmaQuotes, deliveryFee, nonPharmaQuotes]);

  // updating the prescriptions when the items change
  useEffect(() => {
    setPharmItemState(pharmaceuticalItems);
    setNonPharmItemState(nonPharmaceuticalItems);
    setPharmaQuotes([]);
    setNonPharmaQuotes([]);
    setDeliveryFee("");
  }, [pharmaceuticalItems, nonPharmaceuticalItems]);

  // Getting the state for the individual child components
  const getPharmState = (pharmItem: PharmaceuticalItem): CreatePharmaQuoteItemStateModel => {
    const quote = pharmaQuotes.filter(
      (pharmaQuote) => pharmaQuote.pharmaceuticalItemID === pharmItem.id,
    );

    return quote.length === 1
      ? quote[0]
      : {
          name: pharmItem.name,
          dosage: pharmItem.dosage,
          pharmaceuticalItemID: pharmItem.id,
          stocked: true,
          genericDrug: "",
          formulation: "",
          amount: "",
          quantity: "",
        };
  };

  const getNonPharmState = (
    nonPharmItem: NonPharmaceuticalItem,
  ): CreateNonPharmaQuoteItemStateModel => {
    const quote = nonPharmaQuotes.filter(
      (nonPharmaQuote) => nonPharmaQuote.nonPharmaceuticalItemID == nonPharmItem.id,
    );

    return quote.length === 1
      ? quote[0]
      : {
          name: nonPharmItem.name,
          nonPharmaceuticalItemID: nonPharmItem.id,
          stocked: true,
          quantity: `${nonPharmItem.quantity}`,
          amount: "",
        };
  };

  // Submitting the quotations
  const handleSubmit = () => {
    if (pharmaQuotes.length) {
      const quotesToSubmit: CreateQuotation = {
        prescriptionID: prescriptionID,
        deliveryFee: +deliveryFee,
        pharmaceuticalQuotationItems: [],
        nonPharmaceuticalQuotationItems: [],
      };

      pharmaQuotes.map((quote) => {
        if (!quote.genericDrug && !quote.stocked && (!+quote.amount || !+quote.quantity)) {
          quote.genericDrug = "N/A";
          quote.amount = "0";
          quote.quantity = "0";
          quote.stocked = false;
        }
        return quotesToSubmit.pharmaceuticalQuotationItems.push(quote);
      });
      nonPharmaQuotes.map((quote) => {
        if (!quote.stocked && !+quote.amount) {
          quote.amount = "0";
          quote.stocked = false;
        }
        return quotesToSubmit.nonPharmaceuticalQuotationItems.push(quote);
      });

      setSubmitting(true);
      createQuotation(quotesToSubmit);
      setTimeout(() => {
        removePrescription(prescriptionID);
        setSubmitting(false);
        setModalOpen(false);
      }, 1000);
    }
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid
          item
          container
          justify="space-between"
          component={Box}
          py="1rem"
          px="1.2rem"
          className={classes.toolbar}
        >
          {/* TODO add fontweightbold */}
          <Typography variant="subtitle2" color="primary">
            QUOTATION:{" "}
            <Typography component="span" color="secondary">
              <Box component="span" fontWeight="fontWeightMedium">
                {shortenID(prescriptionID)}
              </Box>
            </Typography>
          </Typography>

          <Typography className={classes.viewImageBtn} onClick={handleImageModalOpen}>
            View image
          </Typography>
        </Grid>
        <TableContainer className={classes.container}>
          {pharmItemState.length > 0 && (
            <Grid container justifyContent="center">
              <Box p={1}>
                <Typography variant="subtitle2" color="primary">
                  PHARMACEUTICAL ITEMS
                </Typography>
              </Box>
            </Grid>
          )}
          {pharmItemState.length > 0 &&
            pharmItemState.map((pharmItem) => (
              <CreatePharmQuoteItem
                key={pharmItem.id}
                pharmaceuticalItem={pharmItem}
                onChange={handlePharmaChange}
                state={getPharmState(pharmItem)}
              />
            ))}

          {nonPharmItemState.length > 0 && (
            <Grid container justifyContent="center">
              <Box p={1}>
                <Typography variant="subtitle2" color="primary">
                  NONPHARMACEUTICAL ITEMS
                </Typography>
              </Box>
            </Grid>
          )}
          {nonPharmItemState.length > 0 &&
            nonPharmItemState.map((nonPharmItem) => (
              <CreateNonPharmQuoteItem
                key={nonPharmItem.id}
                nonPharmaceuticalItem={nonPharmItem}
                onChange={handleNonPharmaChange}
                state={getNonPharmState(nonPharmItem)}
              />
            ))}
        </TableContainer>
        {/* Footer */}
        <Grid component={Box} py="0.8rem" px="1.2rem" container justify="space-between">
          <Grid item container alignItems="center" xs={6}>
            <ShoppingCartIcon color="primary" />
            {/*TODO Add fontweightbold and 0.5rem pl*/}
            <Typography color="primary">
              TOTAL:{" "}
              <Typography component="span" color="secondary">
                <Box component="span" fontWeight="fontWeightMedium">
                  KSH {formatCurrency(total)}
                </Box>
              </Typography>
            </Typography>
          </Grid>
          <Grid item container xs={6} justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerate}
              disabled={submitting || (pharmaQuotes.length < 1 && nonPharmaQuotes.length < 1)}
            >
              Generate
            </Button>
          </Grid>
        </Grid>

        {/* Modal */}
        <Dialog
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="confirm quotation"
          aria-describedby="confirm quotation"
          maxWidth="lg"
        >
          <ConfirmQuote
            pharmaQuotes={pharmaQuotes}
            nonPharmaQuotes={nonPharmaQuotes}
            total={total}
            setDeliveryFee={setDeliveryFee}
            deliveryFee={deliveryFee}
            onClose={handleModalClose}
            onSubmit={handleSubmit}
            submitting={submitting}
            deliveryLocation={deliveryLocation}
          />
        </Dialog>
      </Grid>

      {/* Responsive create quote dialog */}
      <div className={classes.responsivePrescItemList}>
        <Grid container justifyContent="space-between">
          <Box p={1}>
            <Typography variant="subtitle2" color="primary">
              PRESC. NO:{" "}
              <Typography component="span" color="secondary">
                <Box component="span" fontWeight="fontWeightMedium">
                  {shortenID(prescriptionID)}
                </Box>
              </Typography>
            </Typography>
          </Box>

          <Box p={1}>
            <Typography className={classes.viewImageBtn} onClick={handleImageModalOpen}>
              View image
            </Typography>
          </Box>
        </Grid>

        <div>
          {pharmItemState.length > 0 && (
            <>
              <Grid container justifyContent="center">
                <Box p={1}>
                  <Typography variant="subtitle2" color="primary">
                    PHARMACEUTICAL ITEMS
                  </Typography>
                </Box>
              </Grid>
              <Divider />
            </>
          )}
          {pharmItemState.length > 0 &&
            pharmItemState.map((pharmItem) => (
              <CreatePharmQuoteItem
                key={pharmItem.id}
                pharmaceuticalItem={pharmItem}
                onChange={handlePharmaChange}
                state={getPharmState(pharmItem)}
              />
            ))}

          {nonPharmItemState.length > 0 && (
            <>
              <Grid container justifyContent="center">
                <Box p={1}>
                  <Typography variant="subtitle2" color="primary">
                    NONPHARMACEUTICAL ITEMS
                  </Typography>
                </Box>
              </Grid>
              <Divider />
            </>
          )}
          {nonPharmItemState.length > 0 &&
            nonPharmItemState.map((nonPharmItem) => (
              <CreateNonPharmQuoteItem
                key={nonPharmItem.id}
                nonPharmaceuticalItem={nonPharmItem}
                onChange={handleNonPharmaChange}
                state={getNonPharmState(nonPharmItem)}
              />
            ))}
        </div>

        <Divider />
        <Grid component={Box} py="0.8rem" px="1.2rem" container justify="space-between">
          <Grid item container alignItems="center" xs={6}>
            <ShoppingCartIcon color="primary" />
            {/*TODO Add fontweightbold and 0.5rem pl*/}
            <Typography color="primary">
              TOTAL:{" "}
              <Typography component="span" color="secondary">
                <Box component="span" fontWeight="fontWeightMedium">
                  KSH {formatCurrency(total)}
                </Box>
              </Typography>
            </Typography>
          </Grid>
          <Grid item container xs={6} justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerate}
              disabled={submitting || (pharmaQuotes.length < 1 && nonPharmaQuotes.length < 1)}
            >
              Generate
            </Button>
          </Grid>
        </Grid>
      </div>

      {/* Image modal */}
      <Dialog
        open={openImageModal}
        onClose={handleImageModalClose}
        aria-labelledby="image modal"
        aria-describedby="image modal"
        maxWidth="lg"
      >
         <div style={{ marginLeft: "auto" }}>
            <IconButton
              color="inherit"
              aria-label="close modal"
              edge="start"
              onClick={handleImageModalClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        <Paper variant="outlined" style={{padding: "10px", display: "flex", flexDirection: "column", margin: "0 15px"}}>
          <img
            src="https://cdn.pixabay.com/photo/2022/02/08/14/42/tablet-7001508_960_720.jpg"
            className={classes.prescriptionImage}
          />
        </Paper>
        <a
          id="image-download"
          href="https://cdn.pixabay.com/photo/2022/02/08/14/42/tablet-7001508_960_720.jpg"
          download
          className={classes.downloadImageBtn}
          target="_blank"
          rel="noreferrer"
        >
          Download Image
        </a>
      </Dialog>
    </>
  );
};

CreateQuote.propTypes = {
  pharmaceuticalItems: PropTypes.array.isRequired,
  nonPharmaceuticalItems: PropTypes.array.isRequired,
  prescriptionID: PropTypes.string.isRequired,
  createQuotation: PropTypes.func.isRequired,
  quotationSending: PropTypes.bool.isRequired,
  removePrescription: PropTypes.func.isRequired,
  deliveryLocation: PropTypes.string.isRequired,
};

const mapStateToProps = (state: RootState) => ({
  quotationSending: state.prescriptions.quotationSending,
});

export default connect(mapStateToProps, { createQuotation })(CreateQuote);
