import { PaginatedServiceResult } from "../../models/common/ServiceResult/PaginatedServiceResult";
import { ServiceResult } from "./../../models/common/ServiceResult/ServiceResult";
import { User } from "./../../models/user/User";
import {
  DELETE_USER_SUCCESS,
  GET_ALL_USERS_SUCCESS,
  GET_USER_BY_ID_SUCCESS,
  UPDATE_USER_SUCCESS,
  LIST_USER_LOADING,
  SINGLE_USER_LOADING,
  USER_ERROR,
  USERS_ERROR,
  CREATE_USER_SUCCESS,
  MAKE_USER_ADMIN_SUCCESS,
} from "./index.constants";

type GetSingleUserResponseType = ServiceResult<User>;
type GetAllUsersResponseType = PaginatedServiceResult<User>;

export type SingleUserActions =
  | { type: typeof GET_USER_BY_ID_SUCCESS; payload: GetSingleUserResponseType }
  | { type: typeof UPDATE_USER_SUCCESS }
  | { type: typeof DELETE_USER_SUCCESS }
  | { type: typeof SINGLE_USER_LOADING }
  | { type: typeof CREATE_USER_SUCCESS }
  | { type: typeof MAKE_USER_ADMIN_SUCCESS }
  | { type: typeof USER_ERROR };

export type ListUserActions =
  | { type: typeof GET_ALL_USERS_SUCCESS; payload: GetAllUsersResponseType }
  | { type: typeof LIST_USER_LOADING }
  | { type: typeof USERS_ERROR };

export const singleUserLoading = (): SingleUserActions => ({
  type: SINGLE_USER_LOADING,
});

export const listUserLoading = (): ListUserActions => ({
  type: LIST_USER_LOADING,
});

export const getUserByIDSuccess = (data: GetSingleUserResponseType): SingleUserActions => ({
  type: GET_USER_BY_ID_SUCCESS,
  payload: data,
});

export const getAllUsersSuccess = (data: GetAllUsersResponseType): ListUserActions => ({
  type: GET_ALL_USERS_SUCCESS,
  payload: data,
});

export const listUsersError = (): ListUserActions => ({
  type: USERS_ERROR,
});

export const createUserSuccess = (): SingleUserActions => ({
  type: CREATE_USER_SUCCESS,
});

export const makeUserAdminSuccess = (): SingleUserActions => ({
  type: MAKE_USER_ADMIN_SUCCESS,
});

export const updateUserSuccess = (): SingleUserActions => ({
  type: UPDATE_USER_SUCCESS,
});

export const deleteUserSucess = (): SingleUserActions => ({
  type: DELETE_USER_SUCCESS,
});

export const singleUserError = (): SingleUserActions => ({
  type: USER_ERROR,
});
