import React from "react";
import { Helmet } from "react-helmet";
import { Box, Typography } from "@mui/material";
import image404 from "../../assets/images/notfound/image404.svg";

const NotFound = () => (
  <>
    <Helmet>
      <title>404 | Not Found</title>
    </Helmet>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography align="center" color="textPrimary" variant="h2">
        404: Page Not Found
      </Typography>
      <Typography align="center" color="textPrimary" variant="h4">
        The page you are looking for isn’t here
      </Typography>
      <Box sx={{ textAlign: "center" }}>
        <Box
          sx={{
            mt: "50px",
            display: "inline-block",
            maxWidth: "100%",
            width: 560,
          }}
          component="img"
          src={image404}
          alt="Could not find the page"
        />
      </Box>
    </Box>
  </>
);

export default NotFound;
