import { ServiceResult } from "../../models/common/ServiceResult/ServiceResult";
import { GetQuotation } from "../../models/getQuotation/GetQuotation";
import { BaseState } from "../index.types";

export class QuotationState extends BaseState<GetQuotation> {
  static setLoading(): QuotationState {
    const state = new QuotationState();
    state.loading = true;
    return state;
  }

  static setEmptyState(): QuotationState {
    return new QuotationState();
  }

  static setReceived(quotations: ServiceResult<GetQuotation>): QuotationState {
    const state = new QuotationState();
    state.results = quotations;
    state.loading = false;
    return state;
  }
}
