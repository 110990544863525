import { GetQuotation } from "../../models/getQuotation/GetQuotation";
import uris from "../../constants/uris";
import { handleError } from "../notification/index.types";
import { ServiceResult } from "./../../models/common/ServiceResult/ServiceResult";
import { quotationLoading, quotationError, quotationReceived } from "./index.types";
import { httpClient } from "..";

export const loadQuotation = (id: string) => async (dispatch: Function) => {
  type DataType = ServiceResult<GetQuotation>;

  try {
    dispatch(quotationLoading());

    const data: DataType = await httpClient.get(uris.quotation.getSingle(id));

    dispatch(quotationReceived(data));
  } catch (error) {
    dispatch(handleError(error, dispatch, quotationError));
  }
};
