import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, CssBaseline } from "@material-ui/core";

import TopBar from "./TopBar";
import SideNav from "./SideNav";
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.2)",
    },
  },
  root: {
    display: "flex",
    backgroundColor: "rgb(241 244 246)",
    userSelect: "none",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  pageHeightAdjustment: {
    [theme.breakpoints.down("md")]: {
      minHeight: "calc(100vh - 30px)",
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "100vh",
      height: "100%"
    },
  },
}));

const propTypes = {
  children: PropTypes.any.isRequired,
};

type Props = {
  children: React.ReactNode;
};

const MainLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      {/* TopBar */}
      <TopBar />
      <Box className={classes.root}>
        <CssBaseline />
        {/* The side Navigation */}
        <SideNav />
        <Box className={clsx({[classes.content]: true, [classes.pageHeightAdjustment]: true})}>
          <Box className={classes.toolbar} />
          <SideNav />
          {/* The main area */}
          {children}
        </Box>
      </Box>
    </Grid>
  );
};

MainLayout.propTypes = propTypes;

export default MainLayout;
