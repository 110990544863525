import React, { useEffect } from "react";
import PropTypes from "prop-types";

import AccountLayout from "./AccountLayout";
import MainLayout from "./MainLayout";
import { RootState } from "../../reducers/index.types";
import { connect, useDispatch } from "react-redux";
import Toaster from "../Toaster";
import toast from "react-hot-toast";
import { NotificationState } from "./../../reducers/notification/index.types";
import { clearMessages } from "../../actions/notification/index.types";

type Props = {
  notification: NotificationState;
  auth?: boolean;
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ notification, auth = false, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (notification.message) {
      const message = notification.message;
      const type = notification.type;

      if (type === "error") {
        const messages = message.split("\n");

        // Toast message with longest message as width
        if (messages.length > 1) {
          // Get logest message to use as width
          const longest = messages.reduce((a, b) => {
            return a.length > b.length ? a : b;
          });

          messages.forEach((msg) =>
            toast.error(msg, { style: { width: `${longest.length + 5}ch` } }),
          );
        } else {
          toast.error(messages[0]);
        }
      } else if (type === "success") {
        toast.success(message);
      }
      dispatch(clearMessages());
    }
  }, [notification]);

  return (
    <>
      <Toaster />
      {auth ? <MainLayout>{children}</MainLayout> : <AccountLayout>{children}</AccountLayout>}
    </>
  );
};

Layout.propTypes = {
  notification: PropTypes.any.isRequired,
  auth: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state: RootState) => ({
  notification: state.notification,
});

export default connect(mapStateToProps)(Layout);
