import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Menu,
  Card,
  Badge,
  Avatar,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  MenuItem,
  CardHeader,
  CardActionArea,
  ListItemIcon,
} from "@material-ui/core";

import NotificationsIcon from "@material-ui/icons/Notifications";
import Logout from "@mui/icons-material/Logout";
import MoreIcon from "@material-ui/icons/MoreVert";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupIcon from "@mui/icons-material/Group";
import { connect } from "react-redux";

import { getRole, logout } from "../../../../actions/account";
import { EPharmacyLogo } from "../../../common/Misc";
import { RootState } from "../../../../reducers/index.types";
import { Divider } from "./../../../common/Misc";
import routes from "../../../../constants/routes";
import { useHistory } from "react-router-dom";
import { isRoleAdmin, isRoleAgentAdmin } from "./../../../../types/enums/Role";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  root: {
    backgroundColor: "white",
    paddingRight: 0,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    color: "black",
  },
  card: {
    height: "100%",
    "& .MuiCard-root": {
      boxShadow: "none",
      height: "100%",

      "& .MuiCardHeader-root": {
        padding: 0,
        height: "100%",
      },
    },
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  menuCss: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    marginTop: "2.5rem",
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      marginLeft: -0.5,
      marginRight: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
  toolbarCss: {
    backgroundColor: theme.palette.background.default,
  },
}));

const propTypes = {
  logout: PropTypes.func.isRequired,
  getRole: PropTypes.func.isRequired,
  user: PropTypes.any.isRequired,
};

type Props = {
  logout: Function;
  getRole: Function;
  user: any | null;
};

const TopBar: React.FC<Props> = ({ logout, getRole, user }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [userName, setUserName] = useState("");
  const [userPharmacyName, setUserPharmacyName] = useState("");
  const [userRoleIsValid, setUserRoleIsValid] = useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    if (user) {
      setUserName(user.data.user.fullName);
      setUserPharmacyName(user.data.pharmacy.name);
      const role = user.data.user.role;
      setUserRoleIsValid(isRoleAdmin(role) || isRoleAgentAdmin(role));
    }
  });

  const handleProfileMenuOpen = (e: any) => {
    getRole();
    setAnchorEl(e.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogout = () => {
    logout();
  };

  const handleManageUsers = () => {
    history.push(routes.user.list);
  };

  const handleMobileMenuOpen = (e: any) => {
    setMobileMoreAnchorEl(e.currentTarget);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
      
      className={classes.menuCss}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        Account Settings
      </MenuItem>
      {userRoleIsValid && (
        <MenuItem onClick={handleManageUsers}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          Manage Users
        </MenuItem>
      )}
      <Divider />
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      id={mobileMenuId}
      keepMounted
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      style={{marginTop: "2.5rem"}}
    >
      <MenuItem>
        <IconButton aria-label="show 1 new notifications" color="inherit">
          <Badge badgeContent={1} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Typography style={{ marginLeft: "1rem" }}>Notifications</Typography>
      </MenuItem>
      {userRoleIsValid && (
        <MenuItem onClick={handleManageUsers}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          Manage Users
        </MenuItem>
      )}
      <Divider/>
      <MenuItem onClick={handleLogout} style={{ paddingLeft: "2rem" }}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <Box className={classes.grow}>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolbarCss}>
          <EPharmacyLogo to={routes.home} />
          <Box className={classes.grow} />
          <Box className={classes.sectionDesktop}>
            <Grid container spacing={2}>
              {/* Notification icon */}
              <Grid item container alignItems="center" justifyContent="center" xs={2}>
                <IconButton aria-label="show new notifications">
                  <Badge badgeContent={0} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Grid>
              {/* Account details */}
              <Grid item xs={10}>
                <CardActionArea onClick={handleProfileMenuOpen} className={classes.card}>
                  <Card>
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                          {userName ? userName.charAt(0).toUpperCase() : null}
                        </Avatar>
                      }
                      title={userName}
                      subheader={userPharmacyName}
                    />
                  </Card>
                </CardActionArea>
              </Grid>
            </Grid>
          </Box>
          {/* Mobile view */}
          <Box className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
};

TopBar.propTypes = propTypes;

const mapStateToProps = (state: RootState) => ({
  user: state.account.results,
});

export default connect(mapStateToProps, { logout, getRole })(TopBar);
