import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store";
import App from "./App";

import { ThemeProvider as Mui4ThemProvider } from "@material-ui/core";
import theme from "./utils/theme";
import { ThemeProvider } from "@mui/material";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Mui4ThemProvider theme={theme}>
            <App />
          </Mui4ThemProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);
