import uris from "../../constants/uris";
import { handleError, sendSuccessMessage } from "../notification/index.types";
import { notificationMessages } from "../../constants/notifications";
import { WorkOrder } from "./../../models/workOrder/WorkOrder";
import { fetchingWorkOrders, workOrdersReceived, workOrdersError } from "./index.types";
import { PaginatedServiceResult } from "../../models/common/ServiceResult/PaginatedServiceResult";
import { httpClient } from "./../index";
// import { useLocation } from "react-router-dom";


export const loadWorkOrders = () => async (dispatch: Function) => {
  type DataType = PaginatedServiceResult<WorkOrder>;
  try {
    await dispatch(fetchingWorkOrders());
	const data: DataType = await httpClient.get(`${uris.workOrder.getAll}?dispatched=false`);
    
    await dispatch(workOrdersReceived(data));
  } catch (error) {
    await dispatch(handleError(error, dispatch, workOrdersError));
  }
};

export const loadDispatchedWorkOrders = () => async (dispatch: Function) => {
	type DataType = PaginatedServiceResult<WorkOrder>;
	try {
     await dispatch(fetchingWorkOrders());

     const data: DataType = await httpClient.get(`${uris.workOrder.getAll}?dispatched=true`);

     await dispatch(workOrdersReceived(data));
	} catch (error) {
     await dispatch(handleError(error, dispatch, workOrdersError));
	}
  };

export const dispatchWorkOrder = (id: string) => async (dispatch: Function) => {
    try {
      
      dispatch(fetchingWorkOrders());
      await httpClient.get(uris.workOrder.dipatchOrder(id));

      dispatch(sendSuccessMessage(notificationMessages.workOrder.dispatch.success));
      return true

    } catch (error) {
      dispatch(handleError(error, dispatch, workOrdersError));
      return false
    }

  };
