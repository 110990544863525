import React from "react";
import { Box, Grid, CircularProgress } from "@material-ui/core";

const Spinner = () => {
  return (
    <Grid item container justify="center" alignItems="center" component={Box} w={1} h={1}>
      <CircularProgress component={Grid} />
    </Grid>
  );
};

export default Spinner;
