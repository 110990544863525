import {
  CREATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  GET_ALL_USERS_SUCCESS,
  GET_USER_BY_ID_SUCCESS,
  LIST_USER_LOADING,
  MAKE_USER_ADMIN_SUCCESS,
  SINGLE_USER_LOADING,
  UPDATE_USER_SUCCESS,
  USER_ERROR,
} from "../../actions/users/index.constants";
import { ListUserActions, SingleUserActions } from "../../actions/users/index.types";
import { ListUserState, SingleUserState } from "./index.types";
import { USERS_ERROR } from "./../../actions/users/index.constants";

export const singleUser = (state = new SingleUserState(), action: SingleUserActions) => {
  switch (action.type) {
    case GET_USER_BY_ID_SUCCESS:
      return SingleUserState.setReceived(action.payload);
    case SINGLE_USER_LOADING:
      return SingleUserState.setLoading();
    case USER_ERROR:
    case CREATE_USER_SUCCESS:
    case UPDATE_USER_SUCCESS:
    case DELETE_USER_SUCCESS:
    case MAKE_USER_ADMIN_SUCCESS:
      return SingleUserState.setEmptyState();
    default:
      return state;
  }
};

export const listUser = (state = new ListUserState(), action: ListUserActions) => {
  switch (action.type) {
    case GET_ALL_USERS_SUCCESS:
      return ListUserState.setReceived(action.payload);
    case LIST_USER_LOADING:
      return ListUserState.setLoading();
    case USERS_ERROR:
      return new ListUserState();
    default:
      return state;
  }
};
