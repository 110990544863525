import React, { useState } from "react";
import clsx from "clsx";
import {
  Box,
  Grid,
  Button,
  Typography,
  InputLabel,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { Divider } from "../common/Misc";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { changePassword } from "./../../actions/account/index";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    minWidth: "25ch",
  },
  form: {
    "& label, input": {
      fontSize: "0.85rem",
    },
  },
}));

const ResetPass = ({ changePassword }) => {
  const classes = useStyles();
  const [state, setState] = useState({ password1: "", password2: "" });
  const [showPassword, setShowPassword] = useState(false);
  const { password1, password2 } = state;

  // change handler
  const handleChange =
    (name) =>
    ({ target }) => {
      setState({ ...state, [name]: target.value });
    };

  // handle show password change
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  // onSubmit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    const { password1, password2 } = state;

    if (password1 !== password2) {
      alert("Passwords do not match");
      setState({ password1: "", password2: "" });
      return;
    }

    changePassword("WelcomeToPonea123!", password1);
  };

  return (
    <>
      {/* Text */}
      <Typography>Welcome. Please update your password</Typography>

      <Divider />

      {/* Update password form */}
      <Grid
        container
        direction="column"
        component="form"
        onSubmit={handleSubmit}
        className={classes.form}
      >
        <FormControl
          variant="outlined"
          className={clsx(classes.margin, classes.textField)}
          size="small"
          fullWidth
        >
          <InputLabel htmlFor="password">New Password</InputLabel>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            value={password1}
            onChange={handleChange("password1")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  value={password1}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <Visibility fontSize="small" />
                  ) : (
                    <VisibilityOff fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={99}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          className={clsx(classes.margin, classes.textField)}
          size="small"
          fullWidth
        >
          <InputLabel htmlFor="password">Confirm Password</InputLabel>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            value={password2}
            onChange={handleChange("password2")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  value={password2}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <Visibility fontSize="small" />
                  ) : (
                    <VisibilityOff fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={122}
          />
          <Box my="1rem" component={Grid} container justify="flex-end">
            <Button variant="contained" type="submit" color="secondary">
              Change Password
            </Button>
          </Box>
        </FormControl>
      </Grid>
    </>
  );
};

ResetPass.propTypes = {
  changePassword: PropTypes.func.isRequired,
};

export default connect(null, { changePassword })(ResetPass);
