import { PaginatedServiceResult } from "../../models/common/ServiceResult/PaginatedServiceResult";
import { Prescription } from "../../models/prescription/Prescription";
import uris from "../../constants/uris";
import {
  prescriptionsError,
  prescriptionsLoading,
  prescriptionsReceived,
} from "./index.types";
import { handleError } from "../notification/index.types";
import { httpClient } from "./../index";

// Get Prescriptions to be quoted on
export const loadPrescriptions = () => async (dispatch: Function) => {
  type DataType = PaginatedServiceResult<Prescription>;

  try {
    dispatch(prescriptionsLoading());

    const data: DataType = await httpClient.get(uris.toQuote.getAll);

    dispatch(prescriptionsReceived(data));
  } catch (error) {
    dispatch(handleError(error, dispatch, prescriptionsError));
  }
};
