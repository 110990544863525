import { notificationMessages } from "../../constants/notifications";
import uris from "../../constants/uris";
import { handleError, sendSuccessMessage } from "../notification/index.types";
// import { ServiceResult } from "./../../models/common/ServiceResult/ServiceResult";
// import { Service } from "./../../models/serviceCatalogue/Service";
import { httpClient } from "./../index";
import {
  fetchingServiceCatalogue,
  serviceCatalogueError,
  serviceCatalogueReceived,
  savingService,
  serviceSaved
} from "./index.types";

export const loadServiceCatalogue = () => async (dispatch: Function) => {
  // type DataType = ServiceResult<Service[]>;
  type DataType = any;

  try {
    dispatch(fetchingServiceCatalogue());

    const data: DataType = await httpClient.get(uris.serviceCatalogue.getAll);

    dispatch(serviceCatalogueReceived(data));
  } catch (error) {
    dispatch(handleError(error, dispatch, serviceCatalogueError));
  }
};

export const toggleServiceStockedStatus =
  (serviceID: string, stocked: boolean, serviceCode: string) => async (dispatch: Function) => {
    try {
      dispatch(fetchingServiceCatalogue());

      const payload = { stocked, serviceID }
      await httpClient.post(uris.serviceCatalogue.toggleStockedStatus, payload);

      dispatch(
        sendSuccessMessage(
          notificationMessages.serviceCatalogue.stockedToggle.success(serviceCode),
        ),
      );
      dispatch();
    } catch (error) {
      dispatch(handleError(error, dispatch, serviceCatalogueError));
    }
  };

export const saveService = (service: any) => async(dispatch: Function) => {
  try {
    dispatch(savingService());

    const payload = { ...service }
    const response = await httpClient.post(uris.serviceCatalogue.saveService, payload);
    // await httpClient.post(uris.serviceCatalogue.saveService, payload);

    dispatch(
      sendSuccessMessage(
        notificationMessages.serviceCatalogue.saved.success,
      ),
    );
    // dispatch(serviceSaved(response.data));
    dispatch(serviceSaved());
    return response.data
  } catch (error) {
    dispatch(handleError(error, dispatch, serviceCatalogueError));
    throw(error)
  }

}
