import {
  FETCHING_QUOTATION,
  QUOTATION_ERROR,
  QUOTATION_RECEIVED,
} from "../../actions/quotation/index.constants";
import { QuotationActions } from "../../actions/quotation/index.types";
import { QuotationState } from "./index.types";

export default function (state = new QuotationState(), action: QuotationActions) {
  switch (action.type) {
    case FETCHING_QUOTATION:
      return QuotationState.setLoading();
    case QUOTATION_RECEIVED:
      return QuotationState.setReceived(action.payload);
    case QUOTATION_ERROR:
      return QuotationState.setEmptyState();
    default:
      return state;
  }
}
