import { GetQuotation } from "../../models/getQuotation/GetQuotation";
import { ServiceResult } from "./../../models/common/ServiceResult/ServiceResult";
import { FETCHING_QUOTATION, QUOTATION_RECEIVED, QUOTATION_ERROR } from "./index.constants";

type QuotationResponseType = ServiceResult<GetQuotation>;

export type QuotationActions =
  | { type: typeof FETCHING_QUOTATION }
  | { type: typeof QUOTATION_RECEIVED; payload: QuotationResponseType }
  | { type: typeof QUOTATION_ERROR };

export const quotationLoading = (): QuotationActions => ({ type: FETCHING_QUOTATION });

export const quotationReceived = (data: QuotationResponseType): QuotationActions => ({
  type: QUOTATION_RECEIVED,
  payload: data,
});

export const quotationError = (): QuotationActions => ({ type: QUOTATION_ERROR });
