import { BaseState } from "../index.types";

export class CreateQuoteState extends BaseState<null> {
  static setLoading(): CreateQuoteState {
    const state = new CreateQuoteState();
    state.loading = true;
    return state;
  }

  static setCreated(): CreateQuoteState {
    const state = new CreateQuoteState();
    state.loading = false;
    return state;
  }
}
