import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { LinkBtn } from "../common/Button";
const ResetLinkSent = () => {
  return (
    <>
      <Typography paragraph>
        A password reset link has been sent to the specified email. Please click the link to
        reset your password
      </Typography>
      <Grid item container>
        <LinkBtn to="/login" label="Back to login" />
      </Grid>
    </>
  );
};
export default ResetLinkSent;
