// libraries
import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";

// routes
import routes from "./constants/routes";

// Route HOC component
import Route from "./components/Route/index";

// components
import Spinner from "./components/common/Spinner";
import Login from "./components/account/Login";
import ForgotPass from "./components/account/ForgotPass";
import PurchaseOrderList from "./pages/PurchaseOrderList";
import QuotationList from "./pages/QuotationList";
import PrescriptionList from "./pages/PrescriptionList";

import IncomingQuotationList from "./pages/IncomingQuotationList";
import IncomingWorkOrderList from "./pages/WorkOrderList";
import DispatchedWorkOrderList from "./pages/WorkOrderListDisspatched";

import UserList from "./pages/UserList";
import UserUpdate from "./pages/UserUpdate";
import UserCreate from "./pages/UserCreate";
import QuoteView from "./pages/QuoteView";

//Styling
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import ResetPass from "./components/account/ResetPass";
import ResetLinkSent from "./components/account/ResetLinkSent";
import ServiceCatalogue from "./pages/ServiceCatalogue";

const App: React.FC = () => {
  return (
    <Suspense fallback={Spinner}>
      <Switch>
        {/* Account routes, can be accessed without authentication */}
        <Route exact path={routes.account.login} component={Login} />
        <Route exact auth path={routes.account.resetPassword} component={ResetPass} />
        <Route exact path={routes.account.forgotPassword} component={ForgotPass} />
        <Route exact path={routes.account.resetPasswordLinkSent} component={ResetLinkSent} />

        {/* List route */}
        <Route exact auth path={routes.prescription.list} component={PrescriptionList} />
		<Route exact auth path={routes.dashboard} component={Dashboard} />
        <Route
          exact
          auth
          path={routes.incomingQuotation.list}
          component={IncomingQuotationList}
        />

        <Route exact auth path={routes.quotation.list} component={QuotationList} />

        <Route
          exact
          auth
          path={routes.workorders.incoming}
          component={IncomingWorkOrderList}
        />

		<Route
          exact
          auth
          path={routes.workorders.dispatched}
          component={DispatchedWorkOrderList}
        />

        <Route exact auth path={routes.quotation.viewRoute} component={QuoteView} />
        <Route exact auth path={routes.purchaseOrder.list} component={PurchaseOrderList} />

        {/* ServiceCatalogue */}
        <Route exact auth path={routes.serviceCatalogue.list} component={ServiceCatalogue} />

        {/**Manage Users */}
        <Route exact auth admin path={routes.user.list} component={UserList} />
        <Route exact auth admin path={routes.user.create} component={UserCreate} />
        <Route exact auth admin path={routes.user.updateRoute} component={UserUpdate} />

        {/* Redirect to prescription list once logged in if authenticated */}
        <Redirect exact strict from={routes.home} to={routes.prescription.list} />

        {/**404-Page */}
        <Route exact auth path={routes.notfound} component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default App;
