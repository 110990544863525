import { PaginatedServiceResult } from "../../models/common/ServiceResult/PaginatedServiceResult";
import { Prescription } from "../../models/prescription/Prescription";
import { CREATE_QUOTE_ERROR, QUOTATION_CREATED, QUOTATION_SENDING } from "./index.constants";

type ToQuoteResponseType = PaginatedServiceResult<Prescription>;

export type CreateQuotationActions =
  | { type: typeof QUOTATION_SENDING }
  | { type: typeof QUOTATION_CREATED; payload: ToQuoteResponseType }
  | { type: typeof CREATE_QUOTE_ERROR };

export const quotationSending = (): CreateQuotationActions => ({
  type: QUOTATION_SENDING,
});

export const quotationCreated = (data: ToQuoteResponseType): CreateQuotationActions => ({
  type: QUOTATION_CREATED,
  payload: data,
});

export const createQuoteError = (): CreateQuotationActions => ({
  type: CREATE_QUOTE_ERROR,
});
