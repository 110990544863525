import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
// import PerfectScrollbar from 'react-perfect-scrollbar';
// import { Card, CardHeader, Divider } from '@material-ui/core';
// import PurchaseOrderList from '../PurchaseOrderList';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import { connect } from "react-redux";
import { getDashboardValues } from "./../../actions/dashboards/index";
import useInterval from "./../../hooks/useInterval";
// import SummaryPrescriptionsToday from './SummaryPrescriptionsToday';
// import SummaryTotalPrescriptions from './SummaryTotalPrescriptions';
// import SummaryTotalQuotes from './SummaryTotalQuotes';
import SummaryPurchaseOrders from './SummaryPurchaseOrders';


const Dashboard = ({ dashboard, getDashboardValues }) => {
	const [state, setState] = useState([]);
	
	useInterval(getDashboardValues)

	useEffect(() => {
		if (dashboard) setState(dashboard.data);
      }, [dashboard]);

	return (

		<>
			<Helmet>
				<title>Dashboard | E-Pharmacy</title>
			</Helmet>
			<Box
				sx={{
				backgroundColor: 'background.default',
				minHeight: '100%',
				py: 3
				}}
			>
				<Container maxWidth={false}>
				<Grid
					container
					spacing={3}
				>

					<Grid
					item
					lg={3}
					sm={6}
					xl={3}
					xs={12}
					>
						<SummaryPurchaseOrders sx={{ height: '100%' }} value={state.open_orders} title='Open Prescriptions'/>
					</Grid>

					<Grid
					item
					lg={3}
					sm={6}
					xl={3}
					xs={12}
					>
						<SummaryPurchaseOrders sx={{ height: '100%' }} value={state.approved_quotes} title='Approved Quotes'/>
					</Grid>

					<Grid
					item
					lg={3}
					sm={6}
					xl={3}
					xs={12}
					>
						<SummaryPurchaseOrders sx={{ height: '100%' }} value={state.work_orders} title='Pending Orders'/>
					</Grid>

					<Grid
					item
					lg={3}
					sm={6}
					xl={3}
					xs={12}
					>
						<SummaryPurchaseOrders sx={{ height: '100%' }} value={state.dispatched_work_orders} title='Dispatched Orders'/>
					</Grid>

					
					{/* <Grid
					item
					lg={12}
					md={12}
					xl={12}
					xs={12}
					> 
						<Card>
						<CardHeader title="Incoming Quote Requests" />
						<Divider />
						<PerfectScrollbar>
							<PurchaseOrderList />
						</PerfectScrollbar>
						</Card>
					</Grid> */}
				</Grid>
				</Container>
			</Box>
		</>

	)
  
};

const mapStateToProps = (state) => ({
	dashboard: state.dashboard.results
  });

export default connect(mapStateToProps, { getDashboardValues })(Dashboard);