import { Align, Column, Color } from "./index.types";

// A function to create a column object given its id, label and optional align and minWidth
export const createColumn = (
  id: string,
  label: string,
  minWidth?: number,
  {
    align,
    format,
    color,
    bold,
  }: {
    align?: Align;
    format?: Function;
    color?: Color;
    bold?: boolean;
  } = {},
): Column => {
  return {
    id,
    label,
    minWidth: minWidth || 100,
    align: align != null ? align : "inherit",
    format: format || undefined,
    color: color || "inherit",
    bold: bold || false,
  };
};
