import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
// eslint-disable-next-line no-unused-vars
import { Card, CardContent, Dialog, DialogContent, TextField, Grid, DialogTitle, DialogActions, Button } from "@material-ui/core";

import CardHeader from "@material-ui/core/CardHeader";

import { connect } from "react-redux";
import { RootState } from "../../reducers/index.types";
// import { Service } from "./../../models/serviceCatalogue/Service";
// import { ServiceResult } from "./../../models/common/ServiceResult/ServiceResult";
import useInterval from "./../../hooks/useInterval";
import {
  loadServiceCatalogue,
  // toggleServiceStockedStatus,
  saveService
} from "./../../actions/serviceCatalogue/index";
import { Stack, Typography } from "@mui/material";
import Switch from '@material-ui/core/Switch';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainTable: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  EmptySpace: {
    marginBottom: theme.spacing(1),
  },
  responsiveCatalogueList: {
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  responsiveServiceRows: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid gray",
    padding: "10px 0",
  },
}));

type Props = {
  loadServiceCatalogue: Function;
  // serviceCatalogue: ServiceResult<Service[]> | null;
  serviceCatalogue: any;
  // toggleServiceStockedStatus: Function;
  saveService: Function;
};

const ServiceCatalogue: React.FC<Props> = ({
  loadServiceCatalogue,
  serviceCatalogue,
  // toggleServiceStockedStatus,
  saveService
}) => {
  const classes = useStyles();
  const [state, setState] = useState<any[]>([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const editObj = ({id: '', code: '', name: '', charges: '', stocked: false})
  const [editDialogService, setEditDialogService] = useState(editObj)

  useInterval(loadServiceCatalogue);

  useEffect(() => {
    if (serviceCatalogue) setState(serviceCatalogue.data);
  }, [serviceCatalogue]);

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false)
    setEditDialogService(editObj)
  }

  const handleEdit = (service: {id: string, code: string, name: string, charges: string, stocked: boolean}) => {
    setOpenEditDialog(true)
    setEditDialogService(service)
  }

  const onChangeEdit = (event: React.ChangeEvent<any>) => {
    const new_service = {...editDialogService}
    if (event.target.name === 'code') {
      new_service.code = event.target.value
    } else if (event.target.name === 'name') {
      new_service.name = event.target.value
    } else if (event.target.name === 'checked'){
      new_service.stocked = event.target.checked
    } else {
      new_service.charges = event.target.value
    }
    setEditDialogService(new_service)
  }

  const handleStocked = 
  (id: string) => async(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    event.preventDefault()
    await handleSaveService({id: id, stocked: checked})
};

  const handleSaveService = async (service_data: any) =>{
    const id = service_data.id

    await saveService(service_data)
    .then((response: any)=>{
      setState((prevState) =>
        prevState.map((service) => {
          if (service.id === id) {
            service = response
          }
          return service;
        }),
      );
      handleCloseEditDialog()
    })
  }


  const editDialog = () => {
    return (
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        PaperProps={{ style: { width: "55vw", height: "40vh" }}}
      >
        <DialogTitle style={{paddingBottom:'4vh'}}>
          <Typography variant='h5' display="inline">Service Details For </Typography>
          <Typography variant='h5' display="inline" style={{color: 'red'}}>{editDialogService.name}</Typography>
        </DialogTitle>
        <DialogContent>
                <TextField
                  name='code'
                  onChange={onChangeEdit}
                  fullWidth
                  variant="outlined"
                  label='Test Code'
                  value={editDialogService.code}
                  style={{width:'33%', paddingRight:'1vw', paddingBottom:'2vw'}}
                />
                <TextField
                  name='name'
                  onChange={onChangeEdit}
                  fullWidth
                  variant="outlined"
                  label='Test Name'
                  value={editDialogService.name}
                  style={{width:'67%'}}
                />
                <TextField
                  name='charges'
                  onChange={onChangeEdit}
                  fullWidth
                  variant="outlined"
                  label='Charge (Ksh)'
                  value={editDialogService.charges}
                  style={{width:'33%', paddingRight:'1vw'}}
                />
        </DialogContent>
        <DialogActions>
          <Button onMouseUp={handleCloseEditDialog}>Cancel</Button>
          <Button onMouseUp={()=>handleSaveService(editDialogService)}>Save</Button>
        </DialogActions>
	    </Dialog>

    )
  }

  return (
    <Box className={classes.root}>
      <Card>
        <CardHeader title="Service Catalogue"/>
        <CardContent className={classes.mainTable}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              ></Typography>
            </Box>
          </Box>
          <Table aria-label="Service catalogue table">
            <TableHead>
              <TableRow>
                <TableCell align="center">STOCKED</TableCell>
                <TableCell align="left">CODE</TableCell>
                <TableCell align="left">ITEM NAME</TableCell>
                <TableCell align="left">MIN/MAX FEES</TableCell>
                <TableCell align="left">CHARGES</TableCell>
				<TableCell align='left'>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.map((service) => {
                return (
                  <TableRow key={service.id}>
                    <TableCell align="center">
                      <Switch
                        checked={service.stocked}
                        color="secondary"
                        onChange={handleStocked(service.id)}
                        name="checked"
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        variant="subtitle2"
                        sx={{ fontSize: "0.75rem", color: "primary.main" }}
                      >
                        {service.code}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Stack>
                        <Typography variant="subtitle1" sx={{ fontSize: "0.75rem" }}>
                          {service.name}
                        </Typography>
                        <Typography variant="subtitle2" sx={{ fontSize: "0.7rem" }}>
                          {service.description}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">{`Ksh ${service.minFee} - Ksh ${service.maxFee}`}</TableCell>
                    <TableCell align="left">Ksh {service.charges}</TableCell>
            <TableCell align='left'>
              <IconButton
                size='small'
                onMouseUp={()=>{ handleEdit({id: service.id, code: service.code, name: service.name, charges:service.charges, stocked: service.stocked})}}
              >
                <EditIcon/>
              </IconButton>
            </TableCell>
                  </TableRow>
                )})}
            </TableBody>
          </Table>
        </CardContent>
        <CardContent className={classes.responsiveCatalogueList}>

          {state?.map((service) => {
            return (
              <div key={service.id} className={classes.responsiveServiceRows}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", fontSize: "0.85rem" }}>
                    <div>
                      <b>ITEM NAME: </b>
                      <span>{service.name}</span>
                    </div>
                    <div>
                      <span>{service.description}</span>
                    </div>
                    <div>
                      <b>CODE: </b>
                      <span style={{ color: "primary.main" }}>{service.code}</span>
                    </div>
                    <div>
                      <b>STOCKED: </b>
                      <Switch
                        checked={service.stocked}
                        color="secondary"
                        onChange={handleStocked(service.id)}
                      />
                    </div>
                    <div>
                      <b>MIN/MAX FEES: </b>
                      <span>{`Ksh ${service.minFee} - Ksh ${service.maxFee}`}</span>
                    </div>
                    <div>
                      <b>CHARGES: </b>
                      <span>Ksh {service.charges}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </CardContent>
      </Card>
      {editDialogService ? editDialog() : <></>}
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  serviceCatalogue: state.serviceCatalogue.results,
});

// export default connect(mapStateToProps, { loadServiceCatalogue, toggleServiceStockedStatus, saveService })(
  export default connect(mapStateToProps, { loadServiceCatalogue, saveService })(
  ServiceCatalogue,
);
