import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Grid from "@material-ui/core/Grid";
import { CardHeader, Divider } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { shortenID } from "../../common/shortenID";
import routes from "../../constants/routes";
import { connect } from "react-redux";
import Spinner from "./../../components/common/Spinner";
import { loadQuotation } from "./../../actions/quotation/index";
import {
  isStatusPending,
  isStatusCancelled,
  isStatusWon,
  isStatusLost,
} from "../../models/quotationStatus/QuotationStatus";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  EmptySpace: {
    marginBottom: theme.spacing(1),
  },
  status: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "0.8em",
  },
  pending: {
    backgroundColor: theme.palette.warning.light,
  },
  won: {
    background: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  lost: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.common.white,
  },
  cancelled: {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.common.white,
  },
  table: {
    minWidth: 650,
  },

  quotationDetails: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  responsiveQuotationDetails: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  responsiveStatusSect: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
    },
    width: "fit-content",
  },
  pharmQouteItemsRow: {
    borderBottom: "1px solid gray",
    borderTop: "1px solid gray",
    padding: "10px 0",
  },
}));

const QuoteView = ({ loadQuotation, quotation: stateQuotation }) => {
  const classes = useStyles();
  const [quotation, setQuotation] = useState(null);
  const [pharmaQuoteItems, setPharmaQuoteItems] = useState([]);
  const [nonPharmaQuoteItems, setNonPharmaQuoteItems] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    loadQuotation(id);
  }, []);

  useEffect(() => {
    if (stateQuotation) {
      setQuotation(stateQuotation.data);
      setPharmaQuoteItems(stateQuotation.data.pharmaceuticalQuotationItems);
      setNonPharmaQuoteItems(stateQuotation.data.nonPharmaceuticalQuotationItems);
    }
  }, [stateQuotation]);

  return !quotation ? (
    <Spinner />
  ) : (
    <>
      <Breadcrumbs className={classes.EmptySpace} aria-label="breadcrumb">
        <Link color="inherit" to={routes.quotation.list}>
          Quotations
        </Link>
        <Typography color="textPrimary">View Quotation</Typography>
      </Breadcrumbs>

      <div
        className={clsx({
          [classes.root]: true,
          [classes.quotationDetails]: true,
        })}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <Card>
              <CardHeader
                title={
                  <>
                    <Typography variant="subtitle1">QUOTATION DETAILS</Typography>
                  </>
                }
              />
              <Divider />
              <CardContent>
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                      <h2 style={{ color: "#e91e63" }}>#{shortenID(quotation.id)}</h2>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <h4 style={{ color: "#757575" }}>Date</h4>
                      <p style={{ color: "#9e9e9e" }}>{quotation.createdOn}</p>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <h4 style={{ color: "#757575" }}>Presc.No</h4>
                      <p style={{ color: "#9e9e9e" }}>{shortenID(quotation.prescriptionID)}</p>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <h4 style={{ color: "#757575" }}>Delivery Location</h4>
                      <p style={{ color: "#9e9e9e" }}>{quotation.deliveryLocation}</p>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <h4 style={{ color: "#757575" }}>Delivery Fee</h4>
                      <p style={{ color: "#9e9e9e" }}>{quotation.deliveryFee}</p>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <h4 style={{ color: "#757575" }}>Status</h4>
                      <p style={{ color: "#9e9e9e" }}>
                        <Chip
                          size="small"
                          label={quotation.status}
                          className={clsx({
                            [classes.status]: true,
                            [classes.pending]: isStatusPending(quotation.status),
                            [classes.won]: isStatusWon(quotation.status),
                            [classes.lost]: isStatusLost(quotation.status),
                            [classes.cancelled]: isStatusCancelled(quotation.status),
                          })}
                          component="span"
                        />
                      </p>
                    </Grid>
                  </Grid>
                </>
                <Typography variant="h6">QUOTATION ITEMS</Typography>
                <TableContainer>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    style={{ paddingTop: "1rem" }}
                  >
                    Pharmaceutical Quotation Items
                  </Typography>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">#</TableCell>
                        <TableCell align="left">Drug</TableCell>
                        <TableCell align="left">Generic</TableCell>
                        <TableCell align="left">Dose</TableCell>
                        <TableCell align="left">Frequency</TableCell>
                        <TableCell align="left">Duration</TableCell>
                        <TableCell align="left">Unit Price</TableCell>
                        <TableCell align="left">Quantity</TableCell>
                        <TableCell align="left">Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pharmaQuoteItems.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">{index + 1}</TableCell>
                          <TableCell align="left">
                            {item.genericDrug ? item.genericDrug : item.name}
                          </TableCell>
                          <TableCell align="left">{item.genericDrug ? "Yes" : "No"}</TableCell>
                          <TableCell align="left">
                            {item.formulation}({item.dosage})
                          </TableCell>
                          <TableCell align="left"> {item.frequency}</TableCell>
                          <TableCell align="left"> {item.duration}</TableCell>
                          <TableCell align="left"> {item.unitPrice}</TableCell>
                          <TableCell align="left"> {item.quantity}</TableCell>
                          <TableCell align="left"> {item.total}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TableContainer>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    style={{ paddingTop: "1rem" }}
                  >
                    Non-Pharmaceutical Quotation Items
                  </Typography>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">#</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Amount</TableCell>
                        <TableCell align="left">Quantity</TableCell>
                        <TableCell align="left">Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {nonPharmaQuoteItems.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">{index + 1}</TableCell>
                          <TableCell align="left">{item.name}</TableCell>
                          <TableCell align="left">{item.unitPrice}</TableCell>
                          <TableCell align="left">{item.quantity}</TableCell>
                          <TableCell align="left">{item.total}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Grid container spacing={8}>
                  <Grid item xs={12} sm={12}>
                    <Divider />
                    <br />
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6}>
                        <Typography variant="subtitle">
                          <b>TOTAL</b>
                        </Typography>
                      </Grid>
                      <Grid
                        justifyContent="right"
                        flexDirection="column"
                        alignItems="right"
                        item
                        xs={6}
                        sm={6}
                      >
                        <Typography
                          variant="h6"
                          style={{ textAlign: "right", color: "#ef5350" }}
                        >
                          <b>
                            Kes.
                            {(Math.round(quotation.total * 100) / 100).toFixed(2)}
                          </b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

      {/* Responsive quotation details page  */}
      <div
        className={clsx({
          [classes.responsiveQuotationDetails]: true,
        })}
      >
        <Card style={{ display: "flex", flexDirection: "column" }}>
          <CardHeader
            title={
              <>
                <Typography variant="subtitle1">QUOTATION DETAILS</Typography>
              </>
            }
          />
          <Divider />
          <div style={{ padding: "10px" }}>
            <div style={{ marginBottom: "10px" }}>
              <span style={{ color: "#e91e63", fontSize: "1rem" }}>
                #{shortenID(quotation?.id)}
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "60%", fontSize: "0.85rem" }}>
                <div>
                  <b style={{ color: "#757575" }}>Date: </b>
                  <span style={{ color: "#9e9e9e" }}>{quotation?.createdOn}</span>
                </div>
                <div>
                  <b style={{ color: "#757575" }}>PRES.NO: </b>
                  <span style={{ color: "#9e9e9e" }}>
                    {shortenID(quotation?.prescriptionID)}
                  </span>
                </div>
                <div>
                  <b style={{ color: "#757575" }}>Delivery Location: </b>
                  <span style={{ color: "#9e9e9e" }}>{quotation?.deliveryLocation}</span>
                </div>
                <div>
                  <b style={{ color: "#757575" }}>Delivery Fee: </b>
                  <span style={{ color: "#9e9e9e" }}>{quotation?.deliveryFee}</span>
                </div>
              </div>
              <div style={{ width: "40%", fontSize: "0.85rem" }}>
                <div className={classes.responsiveStatusSect}>
                  <b style={{ marginRight: "1.2rem", color: "#757575" }}>STATUS: </b>
                  <div style={{ display: "flex", flexDirection: "column", color: "#9e9e9e" }}>
                    <Chip
                      size="small"
                      label={quotation.status}
                      className={clsx({
                        [classes.status]: true,
                        [classes.pending]: isStatusPending(quotation.status),
                        [classes.won]: isStatusWon(quotation.status),
                        [classes.lost]: isStatusLost(quotation.status),
                        [classes.cancelled]: isStatusCancelled(quotation.status),
                      })}
                      component="span"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Typography variant="h6" style={{ padding: "10px 10px 0 10px" }}>
            QUOTATION ITEMS
          </Typography>
          <div style={{ padding: "10px" }}>
            <Typography variant="subtitle2" color="primary" style={{ paddingTop: "1rem" }}>
              Pharmaceutical Quotation Items
            </Typography>
            <div>
              {pharmaQuoteItems?.map((item, index) => {
                return (
                  <div key={index} className={classes.pharmQouteItemsRow}>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%", fontSize: "0.85rem" }}>
                        <div>
                          <b>Drug: </b>
                          <span>{item.genericDrug ? item.genericDrug : item.name}</span>
                        </div>
                        <div>
                          <b>Generic: </b>
                          <span>{item.genericDrug ? "Yes" : "No"}</span>
                        </div>
                        <div>
                          <b>Dose: </b>
                          <span>
                            {item.formulation}({item.dosage})
                          </span>
                        </div>
                        <div>
                          <b>Frequency: </b>
                          <span>{item.frequency}</span>
                        </div>
                      </div>
                      <div style={{ width: "50%", fontSize: "0.85rem" }}>
                        <div>
                          <b>Duration: </b>
                          <span>{item.duration}</span>
                        </div>
                        <div>
                          <b>Unit Price: </b>
                          <span>{item.unitPrice}</span>
                        </div>
                        <div>
                          <b>Quantity: </b>
                          <span>{item.quantity}</span>
                        </div>
                        <div>
                          <b>Total: </b>
                          <span>{item.total}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ padding: "10px" }}>
            <Typography variant="subtitle2" color="primary" style={{ paddingTop: "1rem" }}>
              Non-Pharmaceutical Quotation Items
            </Typography>
            <div>
              {nonPharmaQuoteItems?.map((item, index) => {
                return (
                  <div key={index} className={classes.pharmQouteItemsRow}>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "100%", fontSize: "0.85rem" }}>
                        <div>
                          <b>Name: </b>
                          <span>{item.name}</span>
                        </div>
                        <div>
                          <b>Amount: </b>
                          <span>{item.unitPrice}</span>
                        </div>
                        <div>
                          <b>Quantity: </b>
                          <span>{item.quantity}</span>
                        </div>
                        <div>
                          <b>Total: </b>
                          <span>{item.total}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              padding: "10px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle">
              <b>TOTAL: </b>
            </Typography>
            <Typography variant="h6" style={{ textAlign: "right", color: "#ef5350" }}>
              <b>
                Kes.
                {(Math.round(quotation.total * 100) / 100).toFixed(2)}
              </b>
            </Typography>
          </div>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  quotation: state.quotation.results,
});

export default connect(mapStateToProps, { loadQuotation })(QuoteView);
