import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import poneaLogo from "../../assets/images/logos/logo.png";
import { Box, Divider as MuiDivider } from "@material-ui/core";

export const Divider = () => (
  <Box my="0.9rem">
    <MuiDivider />
  </Box>
);

// Epharmacy Logo Component
export const EPharmacyLogo = ({ height, to }) => {
  const defaultImg = (
    <img
      src={poneaLogo}
      alt="Ponea Health Logo"
      style={{ height: height ? height : 60 }}
    />
  );

  return to ? <Link to={to}>{defaultImg}</Link> : defaultImg;
};

EPharmacyLogo.propTypes = {
  height: PropTypes.string,
  to: PropTypes.string,
};
