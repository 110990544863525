import { notificationMessages } from "../../constants/notifications";
import { ServiceResult } from "../../models/common/ServiceResult/ServiceResult";
import { LoginResponse } from "../../models/loginResponse/LoginResponse";
import { handleError } from "../notification/index.types";
import { sendSuccessMessage } from "./../notification/index.types";
import {
  accountError,
  accountLoading,
  changePasswordSuccess,
  getRoleSuccess,
  loginSuccess,
  logoutSuccess,
} from "./index.types";
import uris from "../../constants/uris";
import { Role } from "../../types/enums/Role";
import { httpClient } from "./../index";

export const login =
  (email: string, password: string, forgetPassword: boolean = false) =>
  async (dispatch: Function) => {
    type DataType = ServiceResult<LoginResponse>;

    try {
      dispatch(accountLoading());

      const payload = { email, password };
      const data: DataType = await httpClient.post(uris.account.login, payload);

      dispatch(loginSuccess(data, forgetPassword));

      dispatch(sendSuccessMessage(notificationMessages.account.login.success));
    } catch (error) {
      dispatch(handleError(error, dispatch, accountError));
    }
  };

export const logout = () => async (dispatch: Function) => {
  try {
    dispatch(accountLoading());

    await httpClient.post(uris.account.logout);

    dispatch(logoutSuccess());

    dispatch(sendSuccessMessage(notificationMessages.account.logout.success));
  } catch (error) {
    dispatch(handleError(error, dispatch, accountError));
  }
};

export const changePassword =
  (currentPassword: string, newPassword: string) => async (dispatch: Function) => {
    try {
      dispatch(accountLoading());

      const payload = { currentPassword, newPassword };
      await httpClient.post(uris.account.changePassword, payload);

      dispatch(sendSuccessMessage(notificationMessages.account.changePassword.success));

      dispatch(changePasswordSuccess());
    } catch (error) {
      dispatch(handleError(error, dispatch, accountError));
    }
  };

export const getRole = () => async (dispatch: Function) => {
  type DataType = ServiceResult<Role[]>;

  try {
    dispatch(accountLoading());

    const data: DataType = await httpClient.get(uris.common.myRoles);

    dispatch(getRoleSuccess(data));
  } catch (error) {
    dispatch(handleError(error, dispatch, accountError));
  }
};
