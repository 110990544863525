import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Table,
  Button,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TextField,
  Typography,
  DialogContent,
  InputAdornment,
} from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import { confirmPharmaQuoteColumns as pharmaQuoteColumns } from "./index.columns";
import { confirmNonPharmaQuoteColumns as nonPharmaQuoteColumns } from "./index.columns";
import formatCurrency from "../../../../common/formatCurrency";
import {
  CreateNonPharmaQuoteItemStateModel,
  CreatePharmaQuoteItemStateModel,
} from "../index.types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  pharmPrescQuoteTable: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "table",
    },
  },
  pharmPrescQuoteList: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      borderTop: "1px solid gray",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  pharmItemRow: {
    
    borderBottom: "1px solid gray",
    padding: "10px 0",
    width: "100%"
  },
  nonPharmPrescQuoteTable: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "table",
    },
  },
  nonPharmPrescQuoteList: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      borderTop: "1px solid gray",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  nonPharmItemRow: {
    borderBottom: "1px solid gray",
    padding: "10px 0",
    width: "100%"
  },
  bottomSection:{
    [theme.breakpoints.down('md')]:{
      padding: "12px 24px 12px 24px",
    },
    [theme.breakpoints.up('md')]:{
      padding: "12px 24px 12px 40px",
    },
  },
  spacing: {
    padding: "10px 0"
  },
  totalSection:{
    [theme.breakpoints.down('md')]:{
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      margin: "10px 0",
    },
    [theme.breakpoints.up('md')]:{
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  },
  pharmTitle: {
    [theme.breakpoints.down('md')]:{
      fontSize: "15px"
    },
  }
}));

// TODO set appropriate types for onClose and onSubmit functions
type Props = {
  pharmaQuotes: CreatePharmaQuoteItemStateModel[];
  nonPharmaQuotes: CreateNonPharmaQuoteItemStateModel[];
  total: number;
  onClose: any;
  onSubmit: any;
  submitting: boolean;
  setDeliveryFee: React.Dispatch<React.SetStateAction<string>>;
  deliveryFee: string;
  deliveryLocation: string;
};

const ConfirmQuote: React.FC<Props> = ({
  pharmaQuotes,
  nonPharmaQuotes,
  total,
  onClose,
  onSubmit,
  setDeliveryFee,
  deliveryFee,
  submitting,
  deliveryLocation,
}) => {
  const classes = useStyles();
  useEffect(() => {
    if (pharmaQuotes.length == 0) {
      onClose();
    }
  }, [pharmaQuotes]);

  const handleChange =
    (name: string) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      if (name === "deliveryFee") {
        setDeliveryFee(target.value);
      }
    };

  const isNullorEmptySpace = (str: string) => {
    return str === null || str.match(/^ *$/) !== null;
  };

  return (
    <>
      <Box px="2rem" py="1rem">
        <Grid container justify="space-between">
          <Typography variant="h6" color="primary">
            Confirm Quote
          </Typography>
          <Grid item container xs={12} md={6} className={classes.totalSection}>
            <ShoppingCartIcon color="primary" />
            {/* TODO fontweight bold pl "0.5rem" */}
            <Typography color="primary">
              TOTAL:{" "}
              <Typography component="span" color="secondary">
                <Box component="span" fontWeight="fontWeightMedium">
                  KSH {formatCurrency(total)}
                </Box>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* TODO add Table container properties "component={TableContainer}" */}
      <DialogContent>
        {/* Pharma Quote items */}
        {pharmaQuotes.length > 0 && (
          <>
            <Typography variant="subtitle2" color="primary" className={classes.pharmTitle}>
              Pharmaceutical Quotation Items
            </Typography>
            <Table
              stickyHeader
              aria-label="sticky table"
              className={classes.pharmPrescQuoteTable}
            >
              <TableHead>
                <TableRow>
                  {pharmaQuoteColumns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {pharmaQuotes.map((quotation) => {
                  return (
                    <TableRow tabIndex={-1} key={quotation.pharmaceuticalItemID}>
                      {pharmaQuoteColumns.map((column) => {
                        let value;
                        switch (column.id) {
                          case "stocked":
                            value = quotation.stocked ? "Yes" : "No";
                            break;
                          case "genericDrug":
                            value = !isNullorEmptySpace(quotation["genericDrug"])
                              ? quotation.genericDrug
                              : "N/A";
                            break;
                          case "total":
                            value = `KSH ${formatCurrency(
                              +quotation.quantity * +quotation.amount,
                            )}`;
                            break;
                          default:
                            value = quotation[column.id];
                            break;
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {/* Responsive quote items list */}
            <div className={classes.pharmPrescQuoteList}>
              {pharmaQuotes.map((quotation) => {
                return (
                  <div className={classes.pharmItemRow} key={quotation.pharmaceuticalItemID}>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%", fontSize: "0.85rem" }}>
                        <div>
                          <b>DRUG NAME: </b>
                          <span>{quotation?.name}</span>
                        </div>
                        <div>
                          <b>DOSAGE: </b>
                          <span>{quotation?.dosage}</span>
                        </div>
                        <div>
                          <b>DURATION: </b>
                          <span>{quotation?.duration}</span>
                        </div>
                        <div>
                          <b>STOCKED: </b>
                          <span>{quotation.stocked ? "Yes" : "No"}</span>
                        </div>
                      </div>
                      <div style={{ width: "50%", fontSize: "0.85rem" }}>
                        <div>
                          <b>GENERIC: </b>
                          <span>
                            {!isNullorEmptySpace(quotation["genericDrug"])
                              ? quotation.genericDrug
                              : "N/A"}
                          </span>
                        </div>
                        <div>
                          <b>AMOUNT: </b>
                          <span>{quotation?.amount}</span>
                        </div>
                        <div>
                          <b>QUANTITY: </b>
                          <span>{quotation?.quantity}</span>
                        </div>
                        <div>
                          <b>TOTAL: </b>
                          <span>{`KSH ${formatCurrency(
                            +quotation.quantity * +quotation.amount,
                          )}`}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}

        {/* NonPharmaQuote Items */}
        {nonPharmaQuotes.length > 0 && (
          <>
            <Typography variant="subtitle2" color="primary" className={classes.pharmTitle} style={{ paddingTop: "1rem" }}>
              NonPharmaceutical Quotation Items
            </Typography>
            <Table stickyHeader aria-label="sticky table" className={classes.nonPharmPrescQuoteTable}>
              <TableHead>
                <TableRow>
                  {nonPharmaQuoteColumns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {nonPharmaQuotes.map((quotation) => {
                  return (
                    <TableRow tabIndex={-1} key={quotation.nonPharmaceuticalItemID}>
                      {nonPharmaQuoteColumns.map((column) => {
                        let value;
                        switch (column.id) {
                          case "total":
                            value = `KSH ${formatCurrency(
                              +quotation.quantity * +quotation.amount,
                            )}`;
                            break;
                          default:
                            value = quotation[column.id];
                            break;
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {/* Responsive quote items list */}
            <div className={classes.nonPharmPrescQuoteList}>
              {nonPharmaQuotes.map((quotation) => {
                return (
                  <div className={classes.nonPharmItemRow} key={quotation.nonPharmaceuticalItemID}>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%", fontSize: "0.85rem" }}>
                        <div>
                          <b>ITEM NAME: </b>
                          <span>{quotation?.name}</span>
                        </div>
                        <div>
                          <b>AMOUNT: </b>
                          <span>{quotation?.amount}</span>
                        </div>
                        <div>
                          <b>QUANTITY: </b>
                          <span>{quotation?.quantity}</span>
                        </div>
                        <div>
                          <b>TOTAL: </b>
                          <span>{`KSH ${formatCurrency(
                              +quotation.quantity * +quotation.amount,
                            )}`}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </DialogContent>
      <Box className={classes.bottomSection}>
        <Grid container justifyContent="space-between">
          <Grid item container xs={12} md={9}>
            <Grid
              item
              xs={12}
              md={9}
              container
              alignItems="center"
              style={{ display: deliveryLocation == "Store Pickup" ? "none" : "block" }}
              className={classes.spacing}
            >
              <Typography variant="subtitle2" color="primary">
                Delivery Location:{" "}
                <Typography component="span" color="textPrimary" noWrap>
                  {deliveryLocation}
                </Typography>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              component={TextField}
              variant="outlined"
              type="number"
              size="small"
              label="Delivery Fee"
              InputProps={{
                startAdornment: <InputAdornment position="start">Ksh</InputAdornment>,
              }}
              onChange={handleChange("deliveryFee")}
              value={deliveryFee}
              style={{ display: deliveryLocation == "Store Pickup" ? "none" : "block", margin: "20px 0" }}
            />
          </Grid>
          <Grid item container xs={12} md={2} justifyContent="space-between" style={{padding: "20px 0"}}>
            <Button color="secondary" variant="outlined" onClick={onClose}>
              Back
            </Button>
            <Button
              autoFocus
              color="primary"
              variant="contained"
              onClick={onSubmit}
              disabled={
                submitting || (+deliveryFee == 0 && deliveryLocation != "Store Pickup")
              }
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

ConfirmQuote.propTypes = {
  pharmaQuotes: PropTypes.array.isRequired,
  nonPharmaQuotes: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  deliveryFee: PropTypes.string.isRequired,
  setDeliveryFee: PropTypes.func.isRequired,
  deliveryLocation: PropTypes.string.isRequired,
};

export default ConfirmQuote;
