import { Service } from "../../models/serviceCatalogue/Service";
import { ServiceResult } from "./../../models/common/ServiceResult/ServiceResult";
import {
  FETCHING_SERVICE_CATALOGUE,
  SERVICE_CATALOGUE_ERROR,
  SERVICE_CATALOGUE_LOADING,
  SERVICE_CATALOGUE_RECEIVED,
  SAVING_SERVICE,
  SERVICE_SAVED
} from "./index.constants";

type ServiceCatalogueResponseType = ServiceResult<Service[]>;

export type ServiceCatalogueActions =
  | { type: typeof SERVICE_CATALOGUE_LOADING }
  | { type: typeof FETCHING_SERVICE_CATALOGUE }
  | { type: typeof SERVICE_CATALOGUE_RECEIVED; payload: ServiceCatalogueResponseType }
  | { type: typeof SERVICE_CATALOGUE_ERROR }
  | { type: typeof SAVING_SERVICE }
  | { type: typeof SERVICE_SAVED };
  // | { type: typeof SERVICE_SAVED; payload: any };


export const serviceCatalogueLoading = (): ServiceCatalogueActions => ({
  type: SERVICE_CATALOGUE_LOADING,
});

export const fetchingServiceCatalogue = (): ServiceCatalogueActions => ({
  type: FETCHING_SERVICE_CATALOGUE,
});

export const savingService = (): ServiceCatalogueActions => ({
  type: SAVING_SERVICE,
});

export const serviceSaved = (): ServiceCatalogueActions => ({
  type: SAVING_SERVICE,
});

// export const serviceSaved = (
//   data: any,
// ): ServiceCatalogueActions => ({
//   type: SERVICE_SAVED,
//   payload: data,
// });

export const serviceCatalogueReceived = (
  data: ServiceCatalogueResponseType,
): ServiceCatalogueActions => ({
  type: SERVICE_CATALOGUE_RECEIVED,
  payload: data,
});

export const serviceCatalogueError = (): ServiceCatalogueActions => ({
  type: SERVICE_CATALOGUE_ERROR,
});
