export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const ACCOUNT_LOADING = "ACCOUNT_LOADING";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";

export const ACCOUNT_ERROR = "ACCOUNT_ERROR";
