import React, { useState, useEffect } from "react";
import {
  MenuItem,
  Breadcrumbs,
  Link,
  CardHeader,
  CardContent,
  Card,
  Typography,
  TextField,
  Grid,
  makeStyles,
} from "@material-ui/core";

import { Box } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

import { createUser } from "./../../actions/users/index";
import { connect } from "react-redux";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@material-ui/icons/Send";
import routes from "../../constants/routes";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  EmptySpace: {
    marginBottom: theme.spacing(1),
  },
}));

const UserCreate = ({ userLoading, createUser }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    gender: "",
  });

  useEffect(() => {
    setLoading(userLoading);
  }, [userLoading]);

  const handleChange =
    (name) =>
    ({ target }) => {
      setState({ ...state, [name]: target.value });
    };

  const handleSubmit = (event) => {
    event.preventDefault();
    createUser(state);
  };

  return (
    <>
      <Breadcrumbs className={classes.EmptySpace} aria-label="breadcrumb">
        <Link color="textDefault" component={RouterLink} to={routes.user.list}>
          Manage Users
        </Link>
        <Typography color="textPrimary">Create User</Typography>
      </Breadcrumbs>
      <Card>
        <CardHeader title="Create New User" subheader="New User Account" />
        <CardContent>
          {/* <Toaster /> */}
          <div>
            <form className={classes.form} onSubmit={(event) => handleSubmit(event)}>
              <Grid container spacing={2}>
                {/* FullName */}
                <Grid item xs={12} sm={3}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Full Name"
                    value={state.fullName}
                    onChange={handleChange("fullName")}
                    autoFocus
                  />
                </Grid>
                {/* Email */}
                <Grid item xs={12} sm={3}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Email"
                    value={state.email}
                    onChange={handleChange("email")}
                  />
                </Grid>
                {/* Phonenumber */}
                <Grid item xs={12} sm={3}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Phone"
                    value={state.phoneNumber}
                    onChange={handleChange("phoneNumber")}
                  />
                </Grid>
                {/* Gender */}
                <Grid item xs={12} sm={3}>
                  <TextField
                    variant="outlined"
                    select
                    required
                    fullWidth
                    label="Select Gender"
                    value={state.gender}
                    onChange={handleChange("gender")}
                  >
                    {[
                      { label: "-Select Gender-", value: "" },
                      { label: "Male", value: "Male" },
                      { label: "Female", value: "Female" },
                    ].map((option) => (
                      <MenuItem key={option.label} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Box sx={{ mt: "2rem" }}>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loadingPosition="end"
                    color="primary"
                    className={classes.submit}
                    loading={loading}
                    endIcon={<SendIcon />}
                  >
                    Create User
                  </LoadingButton>
                </Box>
              </Grid>
            </form>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  userLoading: state.user.loading,
});

export default connect(mapStateToProps, { createUser })(UserCreate);
