export const notificationMessages = {
  account: {
    login: {
      success: "Logged in successfully",
    },
    logout: {
      success: "Logged out successfully",
    },
    changePassword: {
      success: "Password changed successfully",
    },
  },
  createQuotation: {
    success: "Quotation created successfully",
  },
  user: {
    created: {
      success: "User created successfully",
    },
    updated: {
      success: "User updated successfully",
    },
    deleted: {
      success: "User updated successfully",
    },
    makeAdmin: (fullName: string, admin: boolean): string => {
      return admin ? `${fullName} is now an Admin` : `${fullName} is no longer an Admin`;
    },
  },
  serviceCatalogue: {
    stockedToggle: {
      success: (code: string) => `Service (${code}) status updated successfully`,
    },
    saved: {
      success: 'Service successfully saved'
    }
  },
  workOrder:{
	dispatch: {
		success: `Work order has been dispatched`
	}
  },
  common: {
    authorization: {
      error:
        "You are not authorized to access this part of the application, redirecting you to the home page.",
    },
  },
};
