// import { WorkOrder } from "../../models/workOrder/WorkOrder";
// import { PaginatedServiceResult } from "./../../models/common/ServiceResult/PaginatedServiceResult";
import {
  FETCHING_WORK_ORDERS,
  WORK_ORDERS_ERROR,
  WORK_ORDERS_LOADING,
  WORK_ORDERS_RECEIVED,
} from "./index.constants";

// type WorkOrderResponseType = PaginatedServiceResult<WorkOrder>;


export type WorkOrderActions =
  | { type: typeof WORK_ORDERS_LOADING }
  | { type: typeof FETCHING_WORK_ORDERS }
  | { type: typeof WORK_ORDERS_RECEIVED; payload: any }
  | { type: typeof WORK_ORDERS_ERROR };

export const workOrderLoading = (): WorkOrderActions => ({
  type: WORK_ORDERS_LOADING,
});

export const fetchingWorkOrders = (): WorkOrderActions => ({
  type: FETCHING_WORK_ORDERS,
});

export const workOrdersReceived = (data: any): WorkOrderActions => ({
  type: WORK_ORDERS_RECEIVED,
  payload: data,
});

export const workOrdersError = (): WorkOrderActions => ({
  type: WORK_ORDERS_ERROR,
});
