import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, makeStyles } from "@material-ui/core";

const linkBtnStyles = makeStyles(() => ({
  root: {
    color: "rgb(104 104 214)",
    textTransform: "none",
    fontSize: "13.333px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0)",
      textDecoration: "underline",
    },
  },
}));

// LinkBtn implementation
export const LinkBtn = ({ to, label, ...rest }) => {
  const classes = linkBtnStyles();
  return (
    <Button {...rest} to={to} component={Link} disableRipple className={classes.root}>
      {label}
    </Button>
  );
};
LinkBtn.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

// LargeBtn implementation e.g. Login btn
export const LargeBtn = ({ label, onClick }) => {
  return (
    <Button type="submit" onClick={onClick}>
      {label}
    </Button>
  );
};

LargeBtn.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
