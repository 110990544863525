// import { ServiceResult } from "../../models/common/ServiceResult/ServiceResult";
import { Service } from "../../models/serviceCatalogue/Service";
import { BaseState } from "../index.types";

export class ServiceCatalogueState extends BaseState<Service[]> {
  static setLoading(): ServiceCatalogueState {
    const state = new ServiceCatalogueState();
    state.loading = true;
    return state;
  }

  static setEmptyState(): ServiceCatalogueState {
    return new ServiceCatalogueState();
  }

  // static setReceived(quotations: ServiceResult<Service[]>): ServiceCatalogueState {
  static setReceived(quotations: any): ServiceCatalogueState {
    const state = new ServiceCatalogueState();
    state.results = quotations;
    state.loading = false;
    return state;
  }
}
