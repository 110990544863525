import { PaginatedServiceResult } from "../../models/common/ServiceResult/PaginatedServiceResult";
import { Prescription } from "../../models/prescription/Prescription";
import { BasePaginatedState } from "../index.types";

export class PrescriptionState extends BasePaginatedState<Prescription> {
  quotationSending: boolean = false;

  static setLoading(): PrescriptionState {
    const state = new PrescriptionState();
    state.loading = true;
    return state;
  }

  static setReceived(results: PaginatedServiceResult<Prescription>): PrescriptionState {
    const state = new PrescriptionState();
    state.results = results;
    return state;
  }

  static setSending(): PrescriptionState {
    const state = new PrescriptionState();
    state.quotationSending = true;
    return state;
  }
}
