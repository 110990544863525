import {
	DASHBOARD_VALUES_LOADING,
	DASHBOARD_VALUES_RECEIVED,
	DASHBOARD_ERROR,
  } from "../../actions/dashboards/index.constants";
  import { DashboardActions } from "../../actions/dashboards/index.types";
  import { DashboardState } from "./index.types";
  
  export default function (state = new DashboardState(), action: DashboardActions) {
	switch (action.type) {
      case DASHBOARD_VALUES_LOADING:
		return DashboardState.setLoading();
      case DASHBOARD_VALUES_RECEIVED:
		return DashboardState.setReceived(action.payload);
      case DASHBOARD_ERROR:
		return DashboardState.setEmptyState();
      default:
		return state;
	}
  }
  