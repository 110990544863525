const routes = {
  home: "/",
  account: {
    login: "/login",
    logout: "/logout",
    register: "/register",
    forgotPassword: "/forgot-password",
    resetPassword: "/reset-password",
    changePassword: "/change-password",
    resetPasswordLinkSent: "/reset-link-sent",
    forgotPasswordConfirm: "/forgot-password-confirm",
  },
  dashboard: "/dashboard",
  quote: "/quote",
  notfound: "*",
  prescription: {
    list: "/prescription/list",
  },
  quotation: {
    list: "/quotation/list",
    viewRoute: "/quotation/view/:id",
    view: (id: string) => `/quotation/view/${id}`,
  },
  purchaseOrder: {
    list: "/purchase-order/list",
  },
  incomingQuotation: {
    list: "/incoming-quotation/list",
  },
  workorders: {
    incoming: "/work-orders/incoming",
    dispatched: "/work-orders/dispatched",
  },
  serviceCatalogue: {
    list: "/service-catalogue/list",
  },
  user: {
    list: "/user/list",
    create: "/user/create",
    updateRoute: "/user/update/:id",
    update: (id: string) => `/user/update/${id}`,
  },
};

export default routes;
