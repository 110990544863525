import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Grid,
  Link,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Breadcrumbs,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { getUserByID, updateUser } from "./../../actions/users/index";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import routes from "../../constants/routes";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  EmptySpace: {
    marginBottom: theme.spacing(1),
  },
}));

const UserUpdate = ({ user, getUserByID, updateUser }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [state, setState] = useState({
    id: "",
    fullName: "",
    email: "",
    phoneNumber: "",
  });

  useEffect(() => {
    getUserByID(id);
  }, [id]);

  useEffect(() => {
    if (user) setState(user.data);
  }, [user]);

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateUser(state);
  };

  return (
    <>
      <Breadcrumbs className={classes.EmptySpace} aria-label="breadcrumb">
        <Link component={RouterLink} to={routes.user.list}>
          Manage Users
        </Link>
        <Typography color="textPrimary">Update User</Typography>
      </Breadcrumbs>
      <Card>
        <CardHeader title="Update User" subheader="Edit User Account" />
        <CardContent>
          <div className={classes.paper}>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}></Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoFocus
                    variant="outlined"
                    required
                    fullWidth
                    label="Full Name"
                    value={state.fullName}
                    onChange={handleChange("fullName")}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Email"
                    value={state.email}
                    onChange={handleChange("email")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Phone number"
                    value={state.phoneNumber}
                    onChange={handleChange("phoneNumber")}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Update
              </Button>
            </form>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.results,
});

export default connect(mapStateToProps, { getUserByID, updateUser })(UserUpdate);
