import {
  FETCHING_QUOTATIONS,
  QUOTATIONS_ERROR,
  QUOTATIONS_RECEIVED,
} from "../../actions/quotations/index.constants";
import { QuotationActions as QuotationsActions } from "../../actions/quotations/index.types";
import { QuotationState as QuotationsState } from "./index.types";

export default function (state = new QuotationsState(), action: QuotationsActions) {
  switch (action.type) {
    case FETCHING_QUOTATIONS:
      return QuotationsState.setLoading();
    case QUOTATIONS_RECEIVED:
      return QuotationsState.setReceived(action.payload);
    case QUOTATIONS_ERROR:
    default:
      return state;
  }
}
