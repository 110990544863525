type MessageType = "success" | "error" | "warning" | "info" | null;

export class NotificationState {
  id: string | null = null;
  message: string | null = null;
  type: MessageType = null;

  newMessage(message: string, type: MessageType): void {
    this.id = Date.now().toString();
    this.message = message;
    this.type = type;
  }

  static clearMessage(): NotificationState {
    return new NotificationState();
  }

  static setMessageInfo(infoMessage: string): NotificationState {
    const state = new NotificationState();
    state.newMessage(infoMessage, "info");
    return state;
  }

  static setSuccessMessage(successMessage: string): NotificationState {
    const state = new NotificationState();
    state.newMessage(successMessage, "success");
    return state;
  }

  static setErrorMessage(errorMessage: string): NotificationState {
    const state = new NotificationState();
    state.newMessage(errorMessage, "error");
    return state;
  }

  static handleError(error: any): NotificationState {
    if (error.response) {
      if (error.response.status === 401) {
        return new NotificationState();
      }

      if (error.response.status === 403) {
        const message = "You are not authorized to call this action";
        return NotificationState.setErrorMessage(message);
      }

      if (error.response.status === 404) {
        const message = "The requested resource was not found";
        return NotificationState.setErrorMessage(message);
      }
      let message = error.response.data?.error?.message === undefined ? 'Error processing rerquest' : error.response.data.error.message;

      if (error.response.data.error?.code === 999) {
        message = "Server error, contact Ponea Health for assistance.";
      }

      return NotificationState.setErrorMessage(message);
    }

    return NotificationState.setErrorMessage(error.message);
  }
}
