import {
  LOGIN_SUCCESS,
  ACCOUNT_LOADING,
  LOGOUT_SUCCESS,
  CHANGE_PASSWORD_SUCCESS,
  GET_ROLE_SUCCESS,
} from "../../actions/account/index.constants";
import { AccountActions } from "../../actions/account/index.types";
import { AccountState } from "./index.types";
import { ACCOUNT_ERROR } from "./../../actions/account/index.constants";

export default function (state = new AccountState(), action: AccountActions): AccountState {
  switch (action.type) {
    case ACCOUNT_LOADING:
      return state.setLoading();
    case LOGIN_SUCCESS:
      return AccountState.setUserAndToken(
        action.payload.response,
        action.payload.forgetPassword,
      );
    case LOGOUT_SUCCESS:
      return AccountState.setLogout();
    case CHANGE_PASSWORD_SUCCESS:
      return AccountState.setChangePassword();
    case GET_ROLE_SUCCESS:
      state.setRole(action.payload.data);
      return state;
    case ACCOUNT_ERROR:
      return AccountState.getEmptyState();
    default:
      return state;
  }
}
