import { PaginatedServiceResult } from "../../models/common/ServiceResult/PaginatedServiceResult";
import { Prescription } from "../../models/prescription/Prescription";
import {
  PRESCRIPTIONS_ERROR,
  PRESCRIPTIONS_LOADING,
  PRESCRIPTIONS_RECEIVED,
} from "./index.constants";

type PrescriptionResponseType = PaginatedServiceResult<Prescription>;

export type PrescriptionActions =
  | { type: typeof PRESCRIPTIONS_LOADING }
  | { type: typeof PRESCRIPTIONS_RECEIVED; payload: PrescriptionResponseType }
  | { type: typeof PRESCRIPTIONS_ERROR };

export const prescriptionsLoading = (): PrescriptionActions => ({
  type: PRESCRIPTIONS_LOADING,
});

export const prescriptionsReceived = (
  data: PrescriptionResponseType,
): PrescriptionActions => ({
  type: PRESCRIPTIONS_RECEIVED,
  payload: data,
});

export const prescriptionsError = (): PrescriptionActions => ({
  type: PRESCRIPTIONS_ERROR,
});
