import { createColumn } from "./../../common/createColumn/index";
import { shortenID } from "../../common/shortenID";

export const purchaseOrderListColumns = [
  createColumn("id", "P.O. NO", 100, { format: shortenID }),
  createColumn("date", "DATE", 100),
  createColumn("prescNo", "PRESC. NO", 120, { format: shortenID }),
  createColumn("quoteNo", "QUOTE NO", 120),
  createColumn("patient", "PATIENT", 170, {
    align: "left",
    format: (value: string) => value.toUpperCase(),
  }),
  createColumn("delivery", "DELIVERY", 170, {
    align: "left",
    format: (value: string) => value.toUpperCase(),
  }),
  createColumn("amount", "AMOUNT", 120, {
    align: "right",
    format: (value: number) => value.toFixed(2),
  }),
  createColumn("action", "ACTION", 170, { align: "center" }),
];
