import React from "react";
import PropTypes from "prop-types";
import { Grid, Box, CardMedia, CardContent, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// images
import disclaimer from "../../../assets/images/login/disclaimer.png";
import accountImage from "../../../assets/images/login/accountImage.png";

// EPharmacy logo
import { EPharmacyLogo } from "../../common/Misc";

const useStyles = makeStyles((theme) => ({
  accImg: {
    position: "relative",
    [theme.breakpoints.down("md")]: {
      height: "300px",
    },
    [theme.breakpoints.up("md")]: {
      height: "100vh",
      minHeight: "535px",
    },
    backgroundImage: `url(${accountImage})`,
    backgroundSize: "cover",
    backgroundPosition: "right bottom",
  },
  accMain: {
    height: "100%",
    [theme.breakpoints.down("md")]: {
      margin: "10px 0",
    },
    backgroundColor: "white",
    boxShadow: "none",
  },
  logo: {
    height: 60,
  },
  disclaimer: {
    position: "relative",
    marginTop: "20px",
    "& img": {
      height: "65px",
      width: "100%",
    },
  },
  disclaimerImg: {},
  loginBox: {
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
}));

type Props = {
  children: React.ReactNode;
};

const AccountLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container>
      {/* Left panel */}
      <Grid item xs={12} md={6}>
        {/* Account Layout Image */}
        <Box className={classes.accImg} />
      </Grid>
      {/* Right panel */}
      <Grid container item direction="column" xs={12} md={6}>
        {/* Main Part */}
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          className={classes.accMain}
        >
          <Card className={classes.loginBox}>
            <CardContent component={Box}>
              {/* Ponea Logo */}
              <Grid item component={CardMedia} className={classes.logo}>
                <EPharmacyLogo />
              </Grid>
              {/* To be rendered */}
              <Grid item container direction="column">
                {children}
              </Grid>
              {/* Disclaimer */}
              <Grid item className={classes.disclaimer}>
                <img src={disclaimer} alt="disclaimer" />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

AccountLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AccountLayout;
