import { ServiceResult } from "../../models/common/ServiceResult/ServiceResult";
import { LoginResponse } from "../../models/loginResponse/LoginResponse";
import { Role } from "../../types/enums/Role";
import {
  ACCOUNT_LOADING,
  LOGIN_SUCCESS,
  CHANGE_PASSWORD_SUCCESS,
  LOGOUT_SUCCESS,
  ACCOUNT_ERROR,
  GET_ROLE_SUCCESS,
} from "./index.constants";

export type AccountActions =
  | {
      type: typeof LOGIN_SUCCESS;
      payload: { response: ServiceResult<LoginResponse>; forgetPassword: boolean };
    }
  | { type: typeof ACCOUNT_LOADING }
  | { type: typeof LOGOUT_SUCCESS }
  | { type: typeof CHANGE_PASSWORD_SUCCESS }
  | { type: typeof ACCOUNT_ERROR }
  | { type: typeof GET_ROLE_SUCCESS; payload: ServiceResult<Role[]> };

export const loginSuccess = (
  user: ServiceResult<LoginResponse>,
  forgetPassword: boolean,
): AccountActions => ({
  type: LOGIN_SUCCESS,
  payload: { response: user, forgetPassword: forgetPassword },
});

export const changePasswordSuccess = (): AccountActions => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const accountLoading = (): AccountActions => ({
  type: ACCOUNT_LOADING,
});

export const logoutSuccess = (): AccountActions => ({
  type: LOGOUT_SUCCESS,
});

export const accountError = (): AccountActions => ({
  type: ACCOUNT_ERROR,
});

export const getRoleSuccess = (data: ServiceResult<Role[]>): AccountActions => ({
  type: GET_ROLE_SUCCESS,
  payload: data,
});
