import uris from "../../constants/uris";
import { User } from "../../models/user/User";
import { handleError } from "../notification/index.types";
import { ServiceResult } from "./../../models/common/ServiceResult/ServiceResult";
import { PaginatedServiceResult } from "./../../models/common/ServiceResult/PaginatedServiceResult";
import { sendSuccessMessage } from "./../notification/index.types";
import {
  getAllUsersSuccess,
  getUserByIDSuccess,
  updateUserSuccess,
  singleUserLoading,
  listUserLoading,
  singleUserError,
  listUsersError,
  createUserSuccess,
  makeUserAdminSuccess,
} from "./index.types";
import { notificationMessages } from "../../constants/notifications";
import { httpClient } from "./../index";

export const createUser = (user: User) => async (dispatch: Function) => {
  try {
    dispatch(singleUserLoading());

    await httpClient.post(uris.users.create, user);

    dispatch(createUserSuccess());

    dispatch(sendSuccessMessage(notificationMessages.user.created.success));
  } catch (error) {
    dispatch(handleError(error, dispatch, singleUserError));
  }
};

export const getUserByID = (id: string) => async (dispatch: Function) => {
  type DataType = ServiceResult<User>;
  try {
    dispatch(singleUserLoading());

    const data: DataType = await httpClient.get(uris.users.getSingle(id));

    dispatch(getUserByIDSuccess(data));
  } catch (error) {
    dispatch(handleError(error, dispatch, singleUserError));
  }
};

export const makeUserAdmin =
  (fullName: string, id: string, admin: boolean) => async (dispatch: Function) => {
    try {
      dispatch(singleUserLoading());

      const queryParams = [{ name: "admin", value: admin }];
      const url = uris.addQueryParams(`${uris.users.makeAdmin}/${id}`, queryParams);

      await httpClient.post(url);

      dispatch(makeUserAdminSuccess());

      dispatch(sendSuccessMessage(notificationMessages.user.makeAdmin(fullName, admin)));
    } catch (error) {
      dispatch(handleError(error, dispatch, singleUserError));
    }
  };

export const getAllUsers = () => async (dispatch: Function) => {
  type DataType = PaginatedServiceResult<User>;
  try {
    dispatch(listUserLoading());

    const data: DataType = await httpClient.get(uris.users.getAll);

    dispatch(getAllUsersSuccess(data));
  } catch (error) {
    dispatch(handleError(error, dispatch, listUsersError));
  }
};

export const updateUser = (user: User) => async (dispatch: Function) => {
  try {
    dispatch(singleUserLoading());

    await httpClient.put(uris.users.update(user.id), user);

    dispatch(updateUserSuccess());

    dispatch(sendSuccessMessage(notificationMessages.user.updated.success));
  } catch (error) {
    dispatch(handleError(error, dispatch, singleUserError));
  }
};

export const deleteUser = (id: string) => async (dispatch: Function) => {
  try {
    dispatch(singleUserLoading());

    await httpClient.delete(uris.users.delete(id));

    dispatch(updateUserSuccess());

    dispatch(sendSuccessMessage(notificationMessages.user.deleted.success));
  } catch (error) {
    dispatch(handleError(error, dispatch, singleUserError));
  }
};
