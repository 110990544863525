import axios, { AxiosRequestConfig } from "axios";
import store from "../store";

export const httpClient = {
  get: async (endpoint: string): Promise<any> => {
    const { data } = await axios.get(endpoint, tokenConfig());
    return data;
  },
  post: async (endpoint: string, payload: any = {}): Promise<any> => {
    const { data } = await axios.post(endpoint, payload, tokenConfig());
    return data;
  },
  put: async (endpoint: string, payload: any): Promise<any> => {
    const { data } = await axios.put(payload, tokenConfig());
    return data;
  },
  delete: async (endpoint: string): Promise<any> => {
    const { data } = await axios.delete(endpoint, tokenConfig());
    return data;
  },
};

const tokenConfig = () => {
  const token = store.getState().account.results?.data.token;

  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Token ${token}` : "",
    },
  };

  return config;
};
