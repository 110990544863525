import {
  QUOTATION_CREATED,
  QUOTATION_SENDING,
} from "../../actions/createQuote/index.constants";
import { CreateQuotationActions } from "../../actions/createQuote/index.types";

import { CreateQuoteState } from "./index.types";

export default function (state = new CreateQuoteState(), action: CreateQuotationActions) {
  switch (action.type) {
    case QUOTATION_SENDING:
      return CreateQuoteState.setLoading();
    case QUOTATION_CREATED:
      return CreateQuoteState.setCreated();
    default:
      return state;
  }
}
