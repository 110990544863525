import {
	DASHBOARD_VALUES_LOADING,
	DASHBOARD_VALUES_RECEIVED,
	DASHBOARD_ERROR
} from "./index.constants";

export type DashboardActions =
  | { type: typeof DASHBOARD_VALUES_LOADING }
  | { type: typeof DASHBOARD_VALUES_RECEIVED, payload: any }
  | { type: typeof DASHBOARD_ERROR }


export const dashboardValuesLoading = (): DashboardActions => ({
  type: DASHBOARD_VALUES_LOADING,
});

export const dashboardValuesReceived = (data: any): DashboardActions => ({
	type: DASHBOARD_VALUES_RECEIVED,
	payload: data,
  }) ;

export const dashboardError = (): DashboardActions => ({
	type: DASHBOARD_ERROR,
  });

