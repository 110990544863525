import { PaginatedServiceResult } from "../../models/common/ServiceResult/PaginatedServiceResult";
import { GetQuotation } from "../../models/getQuotation/GetQuotation";
import { FETCHING_QUOTATIONS, QUOTATIONS_ERROR, QUOTATIONS_RECEIVED } from "./index.constants";

type QuotationResponseType = PaginatedServiceResult<GetQuotation>;

export type QuotationActions =
  | { type: typeof FETCHING_QUOTATIONS }
  | { type: typeof QUOTATIONS_RECEIVED; payload: QuotationResponseType }
  | { type: typeof QUOTATIONS_ERROR };

export const quotationsLoading = (): QuotationActions => ({ type: FETCHING_QUOTATIONS });

export const quotationsReceived = (data: QuotationResponseType): QuotationActions => ({
  type: QUOTATIONS_RECEIVED,
  payload: data,
});

export const quotationsError = (): QuotationActions => ({ type: QUOTATIONS_ERROR });
