import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Checkbox,
  Typography,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import SendIcon from "@material-ui/icons/Send";

import { Divider } from "../common/Misc";
import { makeStyles } from "@material-ui/styles";
import { LinkBtn } from "../common/Button";
import Textfield from "../formUI/Textfield";
import { connect } from "react-redux";
import { login } from "./../../actions/account/index";
import { setToLocalStorage } from "../../reducers/account/index.types";

const useStyles = makeStyles((theme) => ({
  textField: {
    minWidth: "25ch",
    margin: theme.spacing(1),
  },
  form: {
    "& label, input": {
      fontSize: "0.85rem",
    },
  },
  rememberMe: {
    "& .MuiFormControlLabel-label": {
      fontSize: "0.85rem",
    },
  },
  helperText: {
    fontSize: "0.65rem",
  },
}));

const Login = ({ login, accountLoading, resetPassword }) => {
  const classes = useStyles();
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [tempPassword, setTempPassword] = useState(null);
  const [tempEmail, setTempEmail] = useState(null);
  const [loading, setLoading] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const paramEmail = searchParams.get("e");
  const paramPassword = searchParams.get("p");

  useEffect(() => {
    if (paramPassword && paramEmail) {
      setTempPassword(paramPassword);
      setTempEmail(paramEmail);
    }
  }, []);

  useEffect(() => {
    if (tempPassword && tempEmail) {
      handleSubmit({ email: tempEmail, password: tempPassword });
    }
  }, [tempPassword]);

  useEffect(() => {
    setLoading(accountLoading);
  }, [accountLoading]);

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleSubmit = async ({ email, password }) => {
    if (password === "WelcomeToPonea123!") resetPassword = true;
    if (resetPassword)
      setToLocalStorage([
        { key: "email", value: email },
        { key: "password", value: password },
      ]);
    login(email, password, !rememberMe);
  };

  const otherTextfieldProps = {
    FormHelperTextProps: { className: classes.helperText },
    className: classes.textField,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Provide a valid email address").required("Required."),
    password: Yup.string().required("Required"),
  });

  return (
    <>
      <Typography paragraph>Welcome back. Please sign in to access your account</Typography>
      <Divider />
      <Formik
        // Initial state
        initialValues={{
          email: paramEmail || "",
          password: paramPassword || "",
        }}
        // Validation schema for the objects
        validationSchema={validationSchema}
        // OnSubmit handler
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid item className={classes.form}>
            <Box my="0.7rem">
              <Grid container spacing={1}>
                {/* Email */}
                <Grid item xs={12} lg={6}>
                  <Textfield
                    autoFocus={tempEmail || true}
                    name="email"
                    label="Email"
                    {...otherTextfieldProps}
                  />
                </Grid>
                {/* Password  */}
                <Grid item xs={12} lg={6}>
                  <Textfield
                    autoFocus={tempPassword}
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    {...otherTextfieldProps}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Visibility fontSize="small" />
                            ) : (
                              <VisibilityOff fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid container item justify="space-between">
              {/* Keep me logged in */}
              <Grid
                item
                component={FormControlLabel}
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={handleRememberMe}
                    size="small"
                    name="rememberMe"
                  />
                }
                className={classes.rememberMe}
                label="Keep me logged in"
              />
              {/* Forgot Password redirect */}
              <LinkBtn color="primary" label="Forgot Password?" to="/forgot-password" />
            </Grid>
            <Divider />
            <Grid container justify="center">
              {/* Log in  */}
              <LoadingButton
                variant="contained"
                type="submit"
                loadingPosition="end"
                color="secondary"
                loading={loading}
                endIcon={<SendIcon />}
              >
                Login to Ponea Health
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accountLoading: state.account.loading,
  resetPassword: state.account.resetPassword,
});

export default connect(mapStateToProps, { login })(Login);
