import {
  FETCHING_WORK_ORDERS,
  WORK_ORDERS_ERROR,
  WORK_ORDERS_LOADING,
  WORK_ORDERS_RECEIVED,
} from "../../actions/workOrders/index.constants";
import { WorkOrderActions } from "../../actions/workOrders/index.types";
import { WorkOrderState } from "./index.types";

export default function (state = new WorkOrderState(), action: WorkOrderActions) {
  switch (action.type) {
    case WORK_ORDERS_LOADING:
    case FETCHING_WORK_ORDERS:
      return WorkOrderState.setLoading();
    case WORK_ORDERS_RECEIVED:
      return WorkOrderState.setReceived(action.payload);
    case WORK_ORDERS_ERROR:
      return WorkOrderState.setEmptyState();
    default:
      return state;
  }
}
