export const SERVICE_CATALOGUE_LOADING = "SERVICE_CATALOGUE_LOADING";

export const FETCHING_SERVICE_CATALOGUE = "FETCHING_SERVICE_CATALOGUE";

export const SERVICE_CATALOGUE_RECEIVED = "SERVICE_CATALOGUE_RECEIVED";

export const SAVING_SERVICE = "SAVING_SERVICE";

export const SERVICE_SAVED = "SERVICE_SAVED";

export const SERVICE_CATALOGUE_ERROR = "QUOTATION_ERROR";
