import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  List,
  Grid,
  Badge,
  Drawer,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from "@material-ui/core";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ListIcon from "@material-ui/icons/List";
import HomeIcon from "@material-ui/icons/Home";
import StorefrontIcon from "@material-ui/icons/Storefront";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@mui/icons-material/Close";
// import { navItems } from "./index.navitems";
import routes from "../../../../constants/routes";
import { connect } from "react-redux";
import { RootState } from "../../../../reducers/index.types";
import { PaginatedServiceResult } from "./../../../../models/common/ServiceResult/PaginatedServiceResult";
import { Prescription } from "./../../../../models/prescription/Prescription";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  hide: {
    display: "none",
  },
  drawer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
      width: drawerWidth,
    },
    flexShrink: 0,
    whiteSpace: "nowrap",
    "& .MuiDrawer-paper": {
      width: drawerWidth,
      zIndex: 1000,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },

  navLink: {
    color: "rgb(0 0 0 / 71%)",
    textDecoration: "none",
    marginBottom: "0.5rem",
    borderRadius: "0.45rem",
  },
  navLinkActive: {
    color: "rgb(63 106 216)",
    backgroundColor: theme.palette.action.selected,
    fontWeight: theme.typography.fontWeightMedium,
    "& .MuiListItemIcon-root": {
      color: "rgb(63 106 216)",
    },
  },
  menuToggler: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      color: "black",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mobileBox: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
      width: "0",
      padding: "5px 0",
    },
  },
  mobileDrawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    "& .MuiDrawer-paper": {
      width: drawerWidth,
      zIndex: 1000,
    },
  },
  menuToggler2: {
    marginLeft: "auto",
  },
}));

type Props = {
  prescriptions: PaginatedServiceResult<Prescription> | null;
};

const SideNav: React.FC<Props> = ({ prescriptions }) => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [noOfIncomingPresc, setNoOfIncomingPresc] = useState(0);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpen = () => {
    const new_val = !open
    setOpen(new_val)
    closeDrawer()
  }

  const closeDrawer = () => {
    setMobileOpen(false);
  };

  useEffect(() => {
    if (prescriptions) setNoOfIncomingPresc(prescriptions.totalRecords);
  }, [prescriptions]);


  const renderBaseNavLink = (key: string, route: string, text: string , icon: any, is_sec: boolean) => {
    return (
      <ListItem
        button
        disableRipple
        key={key}
        to={route}
        component={NavLink}
        className={classes.navLink}
        activeClassName={classes.navLinkActive}
        onClick={closeDrawer}
        style={is_sec ? {paddingLeft:'3vw'} : {}}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    )
  }

  const renderCollapseNavLinks = (key: string, title: string) => {
    const nav_list = [
      renderBaseNavLink('incomingWorkOrders', routes.workorders.incoming, 'Pending', <ListIcon/>, true),
      renderBaseNavLink('dispatchedWorkOrders', routes.workorders.dispatched, 'Dispatched',  <ListIcon/>, true),
    ]
    return (
      <>
        <ListItem button key={key} onClick={handleOpen}>
            <ListItemIcon>{<ListIcon />}</ListItemIcon>
            <ListItemText primary={title} />
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List>
                {nav_list}
            </List>
        </Collapse>
      </>
    )
  }


  const navLinks = [
      renderBaseNavLink('dashboard', routes.dashboard, 'Dashboard', <HomeIcon/>, false),
      <Badge
        badgeContent={noOfIncomingPresc}
        color="secondary"
        key={'prescriptions'}
      >
        {renderBaseNavLink('prescriptions_list', routes.prescription.list, 'Prescriptions', <LocalOfferIcon/>, false)}
      </Badge>,
      renderCollapseNavLinks('work_orders', 'Work Orders'),
      renderBaseNavLink('quotations', routes.quotation.list, 'Quotations', <StorefrontIcon/>, false),
      renderBaseNavLink('serviceCatalogue', routes.serviceCatalogue.list, 'Service Catalogue', <ListIcon/>, false),
  ]

  return (
    // shrinkable drawer
    <>
      <Drawer variant="permanent" className={classes.drawer}>
        <Box className={classes.toolbar} />
        <Box mx="0.8rem" mt="1rem">
          <List className={classes.navLink} component={Grid} container direction="column">
            {/* Nav links */}
            {navLinks}
          </List>
        </Box>
      </Drawer>
      <Box className={classes.mobileBox}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuToggler}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          className={classes.mobileDrawer}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuToggler2}
          >
            <CloseIcon />
          </IconButton>
          <Box mx="0.8rem" mt="1rem">
            <List className={classes.navLink} component={Grid} container direction="column">
              {navLinks}
            </List>
          </Box>
        </Drawer>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  prescriptions: state.prescriptions.results,
});

export default connect(mapStateToProps)(SideNav);
