import { PaginatedServiceResult } from "../../models/common/ServiceResult/PaginatedServiceResult";
import { GetQuotation } from "../../models/getQuotation/GetQuotation";
import { BasePaginatedState } from "../index.types";

export class QuotationState extends BasePaginatedState<GetQuotation> {
  static setLoading(): QuotationState {
    const state = new QuotationState();
    state.loading = true;
    return state;
  }

  static setReceived(quotations: PaginatedServiceResult<GetQuotation>): QuotationState {
    const state = new QuotationState();
    state.results = quotations;
    state.loading = false;
    return state;
  }
}
