import {
  FETCHING_SERVICE_CATALOGUE,
  SERVICE_CATALOGUE_ERROR,
  SERVICE_CATALOGUE_LOADING,
  SERVICE_CATALOGUE_RECEIVED,
  SAVING_SERVICE,
  SERVICE_SAVED
} from "../../actions/serviceCatalogue/index.constants";
import { ServiceCatalogueActions } from "../../actions/serviceCatalogue/index.types";
import { ServiceCatalogueState } from "./index.types";

export default function (
  state = new ServiceCatalogueState(),
  action: ServiceCatalogueActions,
) {
  switch (action.type) {
    case SERVICE_CATALOGUE_LOADING:
    case FETCHING_SERVICE_CATALOGUE:
      return ServiceCatalogueState.setLoading();
    case SERVICE_CATALOGUE_RECEIVED:
      return ServiceCatalogueState.setReceived(action.payload);
    case SAVING_SERVICE:
      return ServiceCatalogueState.setLoading()
    case SERVICE_SAVED:
      // return ServiceCatalogueState.setReceived(action.payload);
      return ServiceCatalogueState.setEmptyState();
    case SERVICE_CATALOGUE_ERROR:
      return ServiceCatalogueState.setEmptyState();
    default:
      return state;
  }
}
