import { PaginatedServiceResult } from "../models/common/ServiceResult/PaginatedServiceResult";
import { ServiceResult } from "../models/common/ServiceResult/ServiceResult";
import { AccountState } from "./account/index.types";
import { CreateQuoteState } from "./createQuote/index.types";
import { QuotationState as QuotationsState } from "./quotations/index.types";
import { PrescriptionState } from "./prescription/index.types";
import { NotificationState } from "./notification/index.types";
import { ListUserState, SingleUserState } from "./users/index.types";
import { QuotationState } from "./quotation/index.types";
import { ServiceCatalogueState } from "./serviceCatalogue/index.types";
import { WorkOrderState } from "./workOrders/index.types";
import { DashboardState } from './dashboard/index.types'

export class Base<T> {
  loading: boolean = false;
  results: T | null = null;
}

export class BaseState<T> extends Base<ServiceResult<T>> {}

export class BasePaginatedState<T> extends Base<PaginatedServiceResult<T>> {}

// A redux state object for the redux store.
export interface RootState {
  account: AccountState;
  prescriptions: PrescriptionState;
  quotations: QuotationsState;
  quotation: QuotationState;
  createQuote: CreateQuoteState;
  workOrders: WorkOrderState;
  serviceCatalogue: ServiceCatalogueState;
  user: SingleUserState;
  users: ListUserState;
  notification: NotificationState;
  dashboard: DashboardState;
}
