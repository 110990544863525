import { logoutSuccess } from "./../account/index.types";
import {
  CLEAR_MESSAGES,
  ERROR_MESSAGE,
  HANDLE_ERROR,
  SUCCESS_MESSAGE,
} from "./index.constants";

export type NotificationActions =
  | { type: typeof ERROR_MESSAGE; payload: string }
  | { type: typeof SUCCESS_MESSAGE; payload: string }
  | { type: typeof HANDLE_ERROR; payload: any }
  | { type: typeof CLEAR_MESSAGES };

export const sendErrorMessage = (errorMessage: string): NotificationActions => ({
  type: ERROR_MESSAGE,
  payload: errorMessage,
});

export const sendSuccessMessage = (successMessage: string): NotificationActions => ({
  type: SUCCESS_MESSAGE,
  payload: successMessage,
});

export const handleError = (
  error: any,
  dispatch: Function,
  action: Function,
): NotificationActions => {
  // dispatch error action associated with state
  dispatch(action());

  if (error.response && error.response.status === 401) {
    dispatch(logoutSuccess());
  }

  return {
    type: HANDLE_ERROR,
    payload: error,
  };
};

export const clearMessages = (): NotificationActions => ({
  type: CLEAR_MESSAGES,
});
