import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Table,
  MenuItem,
  TableRow,
  Collapse,
  TableCell,
  TableHead,
  TextField,
  TableBody,
  IconButton,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { PharmaceuticalItem } from "../../../../models/prescription/PharmaceuticalItem";
import { CreatePharmaQuoteItemStateModel } from "../index.types";
import formatCurrency from "../../../../common/formatCurrency";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  tableHead: {
    "& > *": {
      borderBottom: "unset",
    },
    cursor: "pointer",
  },
  prescriptionItemsTable: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "table",
    },
  },
  responsivePrescriptionItemsList: {
    [theme.breakpoints.down("md")]: {
      display: "block",
      width: "100%",
      padding: "0 10px",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  responsivePrescriptionItemRow: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid gray",
    padding: "10px 0",
    cursor: "pointer",
    width: "100%",
  },
  selectedRow: {
    backgroundColor: "rgba(239, 64, 76, 0.08)",
  },
  detailsSection: {
    display: "flex",
    flexDirection: "column",
  },
}));

const HeaderTableCell = withStyles(() => ({
  root: {
    borderBottom: "none",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    color: "rgb(0 0 0 / 70%)",
    backgroundColor: "rgb(239 64 76 / 11%)",
  },
}))(TableCell);

type Props = {
  pharmaceuticalItem: PharmaceuticalItem;
  onChange: (newState: CreatePharmaQuoteItemStateModel) => void;
  state: CreatePharmaQuoteItemStateModel;
};

const CreatePharmQuoteItem: React.FC<Props> = ({ pharmaceuticalItem, onChange, state }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleOpen = () => {
    setOpen(!open);
  };

  // handle function to handle stocked of prescription item
  const handleStocked = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const stocked = target.value === "true" ? true : false;

    onChange({ ...state, stocked });
  };

  const handleChange =
    (name: string) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      onChange({ ...state, [name]: target.value });
    };

  const isNullorEmptySpace = (str: string) => {
    return str === null || str.match(/^ *$/) !== null;
  };

  return (
    <>
      <Table className={classes.prescriptionItemsTable}>
        <TableHead>
          <TableRow>
            <HeaderTableCell />
            <HeaderTableCell style={{ width: "170px" }}>DRUG NAME</HeaderTableCell>
            <HeaderTableCell align="center" style={{ width: "120px" }}>
              FORMULATION
            </HeaderTableCell>
            <HeaderTableCell align="center" style={{ width: "120px" }}>
              DOSE
            </HeaderTableCell>
            <HeaderTableCell align="center" style={{ width: "120px" }}>
              FREQUENCY
            </HeaderTableCell>
            <HeaderTableCell align="center" style={{ width: "120px" }}>
              DURATION
            </HeaderTableCell>
            <HeaderTableCell align="center">COMMENTS</HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow hover selected className={classes.tableHead} onClick={handleOpen}>
            {/* Dropdown  */}
            <TableCell style={{ width: "20px" }}>
              <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>

            {/* Drug name column heading */}
            <TableCell
              component="th"
              scope="row"
              style={{ width: "170px" }}
              onClick={handleOpen}
            >
              {/* TODO font-weight */}
              <Typography color={state.stocked ? "primary" : "initial"}>
                {pharmaceuticalItem.name}
              </Typography>
            </TableCell>
            <TableCell align="center" style={{ width: "120px" }} color="primary">
              {/* TODO font-weight */}
              <Typography color="secondary">{pharmaceuticalItem.formulation}</Typography>
            </TableCell>
            {/* Dosage column heading */}
            <TableCell
              align="center"
              style={{ width: "120px" }}
              color="primary"
              onClick={handleOpen}
            >
              <Typography color="secondary">{pharmaceuticalItem.dosage}</Typography>
            </TableCell>
            {/* Frequency column heading */}
            <TableCell
              align="center"
              style={{ width: "120px" }}
              color="primary"
              onClick={handleOpen}
            >
              <Typography color="secondary">{pharmaceuticalItem.frequency}</Typography>
            </TableCell>
            {/* Duration column heading */}
            <TableCell align="center" style={{ width: "120px" }} color="primary">
              <Typography color="secondary">{pharmaceuticalItem.duration}</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="caption">
                {!isNullorEmptySpace(pharmaceuticalItem.notes)
                  ? pharmaceuticalItem.notes
                  : "N/A"}
              </Typography>
            </TableCell>
          </TableRow>

          {/* Collapsed part */}
          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                borderBottom: "2px solid rgb(225 225 225)",
              }}
              colSpan={7}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Grid component={Box} container justify="space-evenly" pt="1rem" spacing={1}>
                  {/* Stocked */}
                  {/*TODO Add width in this fields */}

                  <Grid item md={2}>
                    <TextField
                      select
                      required
                      fullWidth
                      variant="outlined"
                      size="small"
                      helperText="Stocked"
                      value={`${state.stocked}`}
                      onChange={handleStocked}
                    >
                      {[
                        { label: "Yes", value: "true" },
                        { label: "No", value: "false" },
                      ].map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {/* Generic drug */}
                  {!state.stocked && (
                    // Generic Drug select
                    // TODO add the width
                    <Grid item md={3}>
                      <TextField
                        fullWidth
                        disabled={state.stocked}
                        variant="outlined"
                        helperText="Generic"
                        size="small"
                        value={state.genericDrug}
                        onChange={handleChange("genericDrug")}
                      />
                    </Grid>
                  )}
                  {/* Amount */}
                  <Grid item md={3}>
                    <TextField
                      required
                      variant="outlined"
                      size="small"
                      helperText="Unit Price"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">Ksh</InputAdornment>,
                      }}
                      type="number"
                      value={state.amount}
                      onChange={handleChange("amount")}
                    />
                  </Grid>
                  {/* Quantity */}
                  <Grid item md={2}>
                    <TextField
                      required
                      variant="outlined"
                      size="small"
                      helperText="Quantity"
                      type="number"
                      value={state.quantity}
                      onChange={handleChange("quantity")}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="flex-end">
                  {/* TODO add bold font weight and margin*/}
                  <Typography color="primary">
                    SUB-TOTAL:{" "}
                    <Typography component="span" color="secondary">
                      <Box component="span" fontWeight="fontWeightMedium">
                        KSH {formatCurrency(+state.amount * +state.quantity)}
                      </Box>
                    </Typography>
                  </Typography>
                </Grid>
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* Responsive prescription item row */}
      <div className={classes.responsivePrescriptionItemsList}>
        <div className={classes.responsivePrescriptionItemRow}>
          <div
            className={clsx({
              [classes.detailsSection]: true,
              [classes.selectedRow]: true,
            })}
            onClick={handleOpen}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%", fontSize: "0.85rem" }}>
                <div>
                  <b>DRUG NAME: </b>
                  <span>
                    <Typography color={state.stocked ? "primary" : "initial"}>
                      {pharmaceuticalItem.name}
                    </Typography>
                  </span>
                </div>
                <div>
                  <b>FORMULATION: </b>
                  <span>
                    <Typography color="secondary">{pharmaceuticalItem.formulation}</Typography>
                  </span>
                </div>
                <div>
                  <b>DOSE: </b>
                  <span>
                    <Typography color="secondary">{pharmaceuticalItem.dosage}</Typography>
                  </span>
                </div>
              </div>
              <div style={{ width: "50%", fontSize: "0.85rem" }}>
                <div>
                  <b>FREQUENCY: </b>
                  <span>
                    <Typography color="secondary">{pharmaceuticalItem.frequency}</Typography>
                  </span>
                </div>
                <div>
                  <b>DURATION: </b>
                  <span>
                    <Typography color="secondary">{pharmaceuticalItem.duration}</Typography>
                  </span>
                </div>
              </div>
            </div>
            <div>
              <b>COMMENTS: </b>
              <div>
                <Typography variant="caption">
                  {!isNullorEmptySpace(pharmaceuticalItem.notes)
                    ? pharmaceuticalItem.notes
                    : "N/A"}
                </Typography>
              </div>
            </div>
          </div>
          <Collapse in={true} timeout="auto" unmountOnExit>
            <Grid component={Box} container pt="1rem" spacing={1}>
              {/* Stocked */}
              {/*TODO Add width in this fields */}

              <Grid item xs={12} md={2} style={{margin:"0.5rem 0"}}>
                <TextField
                  select
                  required
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Stocked"
                  value={`${state.stocked}`}
                  onChange={handleStocked}
                >
                  {[
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" },
                  ].map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* Generic drug */}
              {!state.stocked && (
                // Generic Drug select
                // TODO add the width
                <Grid item xs={12} md={3} style={{margin:"0.5rem 0"}}>
                  <TextField
                    fullWidth
                    disabled={state.stocked}
                    variant="outlined"
                    label="Generic"
                    size="small"
                    value={state.genericDrug}
                    onChange={handleChange("genericDrug")}
                    style={{ width: "100%" }}
                  />
                </Grid>
              )}
              {/* Amount */}
              <Grid item xs={12} md={3} style={{margin:"0.5rem 0"}}>
                <TextField
                  required
                  variant="outlined"
                  size="small"
                  label="Unit Price"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Ksh</InputAdornment>,
                  }}
                  type="number"
                  value={state.amount}
                  onChange={handleChange("amount")}
                  style={{ width: "100%" }}
                />
              </Grid>
              {/* Quantity */}
              <Grid item xs={12} md={2} style={{margin:"0.5rem 0"}}>
                <TextField
                  required
                  variant="outlined"
                  size="small"
                  label="Quantity"
                  type="number"
                  value={state.quantity}
                  onChange={handleChange("quantity")}
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>

            <Grid container justify="flex-end" style={{ marginTop: "1rem" }}>
              {/* TODO add bold font weight and margin*/}
              <Typography color="primary">
                SUB-TOTAL:{" "}
                <Typography component="span" color="secondary">
                  <Box component="span" fontWeight="fontWeightMedium">
                    KSH {formatCurrency(+state.amount * +state.quantity)}
                  </Box>
                </Typography>
              </Typography>
            </Grid>
          </Collapse>
        </div>
      </div>
    </>
  );
};

CreatePharmQuoteItem.propTypes = {
  pharmaceuticalItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    dosage: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    formulation: PropTypes.string.isRequired,
    frequency: PropTypes.string.isRequired,
    notes: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  state: PropTypes.shape({
    name: PropTypes.string.isRequired,
    dosage: PropTypes.string.isRequired,
    pharmaceuticalItemID: PropTypes.string.isRequired,
    stocked: PropTypes.bool.isRequired,
    genericDrug: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,
  }).isRequired,
};

export default CreatePharmQuoteItem;
