import { BasePaginatedState } from "../index.types";
import { WorkOrder } from "./../../models/workOrder/WorkOrder";
import { PaginatedServiceResult } from "./../../models/common/ServiceResult/PaginatedServiceResult";

export class WorkOrderState extends BasePaginatedState<WorkOrder> {
  static setLoading(): WorkOrderState {
    const state = new WorkOrderState();
    state.loading = true;
    return state;
  }

  static setEmptyState(): WorkOrderState {
    return new WorkOrderState();
  }

  static setReceived(quotations: PaginatedServiceResult<WorkOrder>): WorkOrderState {
    const state = new WorkOrderState();
    state.results = quotations;
    state.loading = false;
    return state;
  }
}
