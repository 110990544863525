import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
//import Paper from '@material-ui/core/Paper';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
//import TableContainer from '@material-ui/core/TableContainer';

import uris from "../../constants/uris";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
//import Avatar from '@material-ui/core/Avatar';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Card, CardContent } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
//const { REACT_APP_BASE_URL } = process.env;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  EmptySpace: {
    marginBottom: theme.spacing(1),
  },
}));

const IncomingQuotationList = () => {
  const classes = useStyles();

  const [incomingQuotations, setIncomingQuotations] = useState([]);

  const IncomingQuotationsGet = () => {
    let config = {
      headers: {
        Authorization: "Token " + localStorage.getItem("accessToken"),
      },
    };
    fetch(uris.toQuote.getAll, config)
      .then((res) => res.json())
      .then((result) => {
        setIncomingQuotations(result.data);
      });
  };

  useEffect(() => {
    IncomingQuotationsGet();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Card>
          <CardHeader
            title="All IncomingQuotations"
            subheader="All IncomingQuotation Details"
          />
          <CardContent>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">#</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">IncomingQuotationName</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Phone</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {incomingQuotations.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell align="right">{index + 1}</TableCell>
                    <TableCell align="left">{user.fullName}</TableCell>
                    <TableCell align="left">macmuriithi</TableCell>
                    <TableCell align="left">{user.email}</TableCell>
                    <TableCell align="left">+254729934932</TableCell>

                    <TableCell align="left">
                      <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Button>Quote</Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    </>
  );
};
export default IncomingQuotationList;
