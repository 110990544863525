import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import {
  Grid,
  Button,
  Typography,
  InputLabel,
  FormControl,
  OutlinedInput,
  makeStyles,
} from "@material-ui/core";

import { Divider } from "../common/Misc";
import { LinkBtn } from "../common/Button";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

// TODO: while awaiting for the link to be sent, load the other page
const ForgotPassword = () => {
  const classes = useStyles();

  const [state, setState] = useState({ email: "" });

  const handleChange =
    (name) =>
    ({ target }) => {
      setState({ ...state, [name]: target.value });
    };

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push("/reset-link-sent");
  };
  return (
    <>
      {/* Text */}
      <Typography>Forgot your password?. Use the form below to recover it</Typography>
      {/* Divider */}
      <Divider />
      {/* Email form */}
      <Grid item component="form" onSubmit={handleSubmit}>
        {/* Input for email */}
        <FormControl
          variant="outlined"
          className={clsx(classes.margin, classes.textField)}
          size="small"
          fullWidth
        >
          <InputLabel htmlFor="password">Email</InputLabel>
          <OutlinedInput
            autoFocus
            type="text"
            value={state.username}
            onChange={handleChange("username")}
            labelWidth={40}
          />
        </FormControl>
        {/* Divider */}
        <Divider />
        {/* Buttons */}
        <Grid container item justify="space-between">
          {/* Login redirect */}
          <LinkBtn to="/login" label="Sign in with existing account" />
          {/* Recover Password */}
          <Button variant="contained" type="submit" color="secondary">
            Recover Password
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export const ResetLinkSent = () => {
  return (
    <>
      <Typography paragraph>
        A password reset link has been sent to the specified email. Please click the link to
        reset your password
      </Typography>
      <Grid item container>
        <LinkBtn to="/login" label="Back to login" />
      </Grid>
    </>
  );
};

export default ForgotPassword;
