import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { TableRow, TableCell, Button } from "@material-ui/core";

import { prescriptionRowColumns as columns } from "../index.columns";
import { Prescription } from "../../../models/prescription/Prescription";
import { PharmaceuticalItem } from "../../../models/prescription/PharmaceuticalItem";
import { NonPharmaceuticalItem } from "./../../../models/prescription/NonPharmaceuticalItem";
import { shortenID } from "../../../common/shortenID";

interface Props {
  prescription: Prescription;
  selected: boolean;
  classes: any;
  handleQuote: (toQuote: Prescription) => void;
  openModal?: () => void | undefined;
}

const PrescriptionRow: React.FC<Props> = ({
  prescription,
  selected,
  classes,
  handleQuote,
  openModal,
}) => {
  const [timeLeft, setTimeLeft] = useState("");

  const expiryTimer = () => {
    const difference = +new Date(prescription.expiry) - +new Date();

    if (difference > 0) {
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      // convert the time to have double digits
      setTimeLeft(
        `${minutes < 10 ? `0${minutes}` : minutes}m ${
          seconds < 10 ? `0${seconds}` : seconds
        }s`,
      );
    } else {
      setTimeLeft("expired");
    }

    // call self after 1 second
    setTimeout(expiryTimer, 1000);
  };

  // Change the expiry date
  useEffect(() => {
    expiryTimer();

    return () => {
      clearTimeout();
    };
  });

  return (
    <>
      <TableRow
        hover
        selected={selected}
        role="checkbox"
        key={prescription.id}
        style={{ cursor: "pointer" }}
        className={clsx({
          [classes.prescriptionListRows]: true,
          [classes.selectedStyles]: selected,
        })}
        onClick={() => handleQuote(prescription)}
      >
        {columns.map((column) => {
          let value: number | string | PharmaceuticalItem[] | NonPharmaceuticalItem[];

          switch (column.id) {
            case "expiry":
              value = timeLeft;
              break;
            default:
              value = prescription[column.id];
              break;
          }

          return (
            <TableCell key={column.id} align={column.align}>
              {column.format ? column.format(value) : value}
            </TableCell>
          );
        })}
      </TableRow>

      {/* Responsive prescription list rows */}
      <div
        key={prescription?.id}
        onClick={() => {
          handleQuote(prescription);
          openModal && openModal();
        }}
        className={clsx({
          [classes.responsivePrescriptionRows]: true,
          [classes.selectedStyles]: selected,
          [classes.selectedRowStyles]: selected,
        })}
      >
        <div style={{ marginBottom: "10px" }}>
          <b style={{ fontSize: "1rem" }}>PRESCRIPTION NO: </b>
          <span style={{ color: "#3f51b5", fontSize: "1rem" }}>
            {shortenID(prescription?.id)}
          </span>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "60%", fontSize: "0.85rem" }}>
            <div>
              <b>ITEMS: </b>
              <span>{prescription?.noOfItems}</span>
            </div>
            <div>
              <b>DELIVERY LOCATION: </b>
              <span>{prescription?.deliveryLocation}</span>
            </div>
            <div>
              <b>EXPIRES IN: </b>
              <span style={{ color: "#E30F0F" }}>{timeLeft}</span>
            </div>
          </div>
          <div style={{ width: "40%", fontSize: "0.85rem", marginTop: "auto" }}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ whiteSpace: "nowrap", float:"right" }}
            >
              View Details
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

PrescriptionRow.propTypes = {
  prescription: PropTypes.shape({
    id: PropTypes.string.isRequired,
    expiry: PropTypes.string.isRequired,
    noOfItems: PropTypes.number.isRequired,
    deliveryLocation: PropTypes.string.isRequired,
    pharmaceuticalItems: PropTypes.array.isRequired,
    nonPharmaceuticalItems: PropTypes.array.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handleQuote: PropTypes.func.isRequired,
};

export default PrescriptionRow;
