import { combineReducers } from "redux";
import account from "./account";
import createQuote from "./createQuote";
import { RootState } from "./index.types";
import quotations from "./quotations";
import prescriptions from "./prescription";
import notification from "./notification";
import { listUser as users, singleUser as user } from "./users";
import quotation from "./quotation";
import serviceCatalogue from "./serviceCatalogue";
import workOrders from "./workOrders";
import dashboard from './dashboard'

export default combineReducers<RootState>({
  account,
  prescriptions,
  quotations,
  quotation,
  createQuote,
  workOrders,
  serviceCatalogue,
  user,
  users,
  notification,
  dashboard
});
