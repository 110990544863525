/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Chip,
  Paper,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  LinearProgress,
  TablePagination,
} from "@material-ui/core";
// import { SearchOutlined, FilterList } from "@material-ui/icons";
// import SortIcon from "@mui/icons-material/Sort";
import { quotationListColumns as columns } from "./index.columns";
import { connect } from "react-redux";
import { RootState } from "../../reducers/index.types";
import { loadQuotationsList } from "../../actions/quotations";
import { PaginatedServiceResult } from "../../models/common/ServiceResult/PaginatedServiceResult";
import { GetQuotation } from "../../models/getQuotation/GetQuotation";
import {
  isStatusPending,
  isStatusCancelled,
  isStatusWon,
  isStatusLost,
} from "../../models/quotationStatus/QuotationStatus";
import { containerHeight } from "../../components/Layout/MainLayout/index.constants";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Spinner from "./../../components/common/Spinner";
import useInterval from "./../../hooks/useInterval";
import routes from "./../../constants/routes";
import formatCurrency from "../../common/formatCurrency";
import { shortenID } from "../../common/shortenID";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  mainTable: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  container: {
    height: containerHeight,
  },
  status: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "0.8em",
  },
  pending: {
    backgroundColor: theme.palette.warning.light,
  },
  won: {
    background: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  lost: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.common.white,
  },
  cancelled: {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.common.white,
  },
  tableHead: {
    "& th:first-child": {
      borderTopLeftRadius: theme.shape.borderRadius,
    },
    "& th:last-child": {
      borderTopRightRadius: theme.shape.borderRadius,
    },
  },
  hide: {
    display: "none",
  },
  tableRow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  responsiveQuotationRows: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid gray",
    padding: "10px 0",
  },
  responsiveQuotationList: {
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "10px",
  },
  responsiveStatusSect: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
    },
    width: "fit-content",
    marginLeft: "auto",
  },
  filterSection: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "center",
    ".MuiButton-outlined": {
      padding: "10px 30px",
    },
  },
  paginationRow: {
    "& .MuiToolbar-gutters": {
      padding: "0",
    },
  },
  heightAdjustment: {
    [theme.breakpoints.down("md")]: {
      marginTop: "20vh",
      display: "flex",
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "calc(100vh - 250px)",
    },
  },
}));

type Props = {
  loadedQuotations: PaginatedServiceResult<GetQuotation> | null;
  loadQuotationsList: Function;
  loading: boolean;
};

const QuotationList: React.FC<Props> = ({ loadQuotationsList, loadedQuotations, loading }) => {
  const classes = useStyles();
  const [quotations, setQuotations] = useState<GetQuotation[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();

  useInterval(loadQuotationsList);

  useEffect(() => {
    if (loadedQuotations) {
      setQuotations(loadedQuotations.data);
      setCount(loadedQuotations.totalRecords);
    }
  }, [loadedQuotations]);

  const handleChangePage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e: any) => {
    setRowsPerPage(+e.target.value);

    setPage(0);
  };

  const handleViewQuote = (id: string) => (e: any) => {
    e.preventDefault();
    history.push(routes.quotation.view(id));
  };

  return (
    <>
      <Paper className={classes.mainTable}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className={classes.tableHead}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {quotations.map((quotation) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={quotation.id}
                    onClick={handleViewQuote(quotation.id)}
                    className={classes.tableRow}
                  >
                    {columns.map((column) => {
                      let value;
                      switch (column.id) {
                        case "status":
                          value = (
                            <Chip
                              size="small"
                              label={quotation.status.toUpperCase()}
                              className={clsx({
                                [classes.status]: true,
                                [classes.pending]: isStatusPending(quotation.status),
                                [classes.won]: isStatusWon(quotation.status),
                                [classes.lost]: isStatusLost(quotation.status),
                                [classes.cancelled]: isStatusCancelled(quotation.status),
                              })}
                              component="span"
                            />
                          );
                          break;
                        case "action":
                          value = (
                            <Button
                              onClick={handleViewQuote(quotation.id)}
                              variant="contained"
                              size="small"
                              color="primary"
                            >
                              View Details
                            </Button>
                          );
                          break;
                        default:
                          value = quotation[column.id];
                          break;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Typography
                            component="span"
                            variant={column.bold ? "subtitle2" : "body1"}
                            color={column.color}
                          >
                            {column.format ? column.format(value) : value}
                          </Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {loading && <LinearProgress />}
      </Paper>

      {/* Responsive list for quotations */}
      {quotations?.length > 0 ? (
        <div
          className={clsx({
            [classes.responsiveQuotationList]: true,
          })}
        >
          <div style={{ borderBottom: "1px solid gray" }}>
            <Typography variant="h5" style={{ padding: "1rem 0", fontWeight: "bold" }}>
              Quotations
            </Typography>
          </div>
          {quotations?.map((quotation) => {
            return (
              <div
                key={quotation?.id}
                onClick={handleViewQuote(quotation.id)}
                className={classes.responsiveQuotationRows}
              >
                <div style={{ marginBottom: "10px" }}>
                  <b style={{ fontSize: "1rem" }}>QUOTE NO: </b>
                  <span style={{ color: "#3f51b5", fontSize: "1rem" }}>
                    {shortenID(quotation?.id)}
                  </span>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", fontSize: "0.85rem" }}>
                    <div>
                      <b>Date: </b>
                      <span>{quotation?.createdOn}</span>
                    </div>
                    <div>
                      <b>PRES.NO: </b>
                      <span style={{ color: "#3f51b5" }}>
                        {shortenID(quotation?.prescriptionID)}
                      </span>
                    </div>
                    <div>
                      <b>PRES.ITEMS: </b>
                      <span>{quotation?.noToQuote}</span>
                    </div>
                    <div>
                      <b>QUOTED: </b>
                      <span>{quotation?.noQuoted}</span>
                    </div>
                    <div>
                      <b>TOTAL: </b>
                      <span>{formatCurrency(quotation?.total)}</span>
                    </div>
                  </div>
                  <div style={{ width: "50%", fontSize: "0.85rem" }}>
                    <div className={classes.responsiveStatusSect}>
                      <b style={{ marginRight: "1.2rem" }}>STATUS: </b>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Chip
                          size="small"
                          label={quotation.status.toUpperCase()}
                          className={clsx({
                            [classes.status]: true,
                            [classes.pending]: isStatusPending(quotation.status),
                            [classes.won]: isStatusWon(quotation.status),
                            [classes.lost]: isStatusLost(quotation.status),
                            [classes.cancelled]: isStatusCancelled(quotation.status),
                          })}
                          component="span"
                        />
                        <Button
                          onClick={handleViewQuote(quotation.id)}
                          variant="contained"
                          size="small"
                          color="primary"
                          style={{ marginTop: "10px", whiteSpace: "nowrap" }}
                        >
                          View Details
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              className={classes.paginationRow}
            />
          </div>
        </div>
      ) : null}

      {/* To show when the prescriptions are not there */}
      {quotations?.length > 0 ? null : (
        <Grid container className={clsx({ [classes.heightAdjustment]: true })}>
          {loading ? (
            <Spinner />
          ) : (
            <Typography variant="h4" align="center" component={Box} width={0.75}>
              You have no quotations.
            </Typography>
          )}
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  loadedQuotations: state.quotations.results,
  loading: false,
});

export default connect(mapStateToProps, { loadQuotationsList })(QuotationList);
