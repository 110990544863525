import { PaginatedServiceResult } from "../../models/common/ServiceResult/PaginatedServiceResult";
import { GetQuotation } from "../../models/getQuotation/GetQuotation";
import uris from "../../constants/uris";
import { quotationsError, quotationsLoading, quotationsReceived } from "./index.types";
import { handleError } from "../notification/index.types";
import { httpClient } from "./../index";

export const loadQuotationsList = () => async (dispatch: Function) => {
  type DataType = PaginatedServiceResult<GetQuotation>;

  try {
    dispatch(quotationsLoading());

    // const { data }: QuotationsType = await axios.get(uris.quotation.getAll, tokenConfig());
    const data: DataType = await httpClient.get(uris.quotation.getAll);

    dispatch(quotationsReceived(data));
  } catch (error) {
    dispatch(handleError(error, dispatch, quotationsError));
  }
};
