import React, { useEffect, useState } from "react";
import {
  Route as ReactRouterRoute,
  RouteProps as ReactRouterRouteProps,
  useHistory,
} from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../reducers/index.types";
import Layout from "../Layout";
import routes from "../../constants/routes";
import { ServiceResult } from "../../models/common/ServiceResult/ServiceResult";
import { isRoleAgent } from "../../types/enums/Role";
import { LoginResponse } from "./../../models/loginResponse/LoginResponse";
import { sendErrorMessage } from "../../actions/notification/index.types";
import { notificationMessages } from "./../../constants/notifications";

type Props = {
  component: React.FunctionComponent<any>;
  authenticated: boolean;
  resetPassword: boolean;
  user: ServiceResult<LoginResponse> | null;
  auth?: boolean;
  admin?: boolean;
};

const Route: React.FC<Props & ReactRouterRouteProps> = ({
  component: Component,
  authenticated,
  resetPassword,
  user,
  auth = false,
  admin = false,
  ...rest
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [userRoleAdmin, setUserRoleAdmin] = useState(false);

  useEffect(() => {
    try {
      if (user) setUserRoleAdmin(!isRoleAgent(user.data.user.role));
    } catch {
      localStorage.clear();
      window.location.reload();
    }
  }, [user]);

  const routeIsResetPassword = history.location.pathname === routes.account.resetPassword;

  if (!authenticated && auth) {
    history.push(routes.account.login);
  } else if (resetPassword && authenticated && !routeIsResetPassword) {
    history.push(routes.account.resetPassword);
  } else if ((authenticated && !auth) || (!resetPassword && routeIsResetPassword)) {
    history.push(routes.home);
  }

  if (!userRoleAdmin && admin && authenticated) {
    dispatch(sendErrorMessage(notificationMessages.common.authorization.error));
    history.push(routes.home);
  }

  return (
    <ReactRouterRoute
      {...rest}
      render={(props) => (
        <Layout auth={auth && !resetPassword}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  authenticated: state.account.authenticated,
  resetPassword: state.account.resetPassword,
  user: state.account.results,
});

export default connect(mapStateToProps)(Route);
